import styled from "styled-components";

export const AdvantagesBlock = styled.div`
  margin-top: 130px;
`;

export const HeadText = styled.p`
  line-height: 67.2px;
  font-size: 48px;
  text-align: center;
  color: #5b8b0f;
  font-weight: 700;
`;

export const AdvantagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 30px auto 0px;
    
    @media(max-width: 600px){
        grid-template-columns: 1fr;
        gap: 40px;
    }
`;

export const AdvantagesBlocks = styled.div`
  width: 345px;
  text-align: center;
    margin: 0 auto;
`;

export const ImageBlock = styled.div`
  width: 266px;
  height: 266px;
  margin: 0 auto;
`;

export const AdvantagesText = styled.div`
  color: #82a748;
  line-height: 28px;
  font-size: 20px;
  font-weight: 700;
`;

export const BottomTextBlock = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 80px;
`;

export const TextBlock = styled.div`
  border: 6px solid #5b8b0f;
  padding: 25px 45px 25px 45px;
  width: 807px;
  margin-right: 50px;
`;

export const CursorText = styled.p`
  font-size: 28px;
  line-height: 39.2px;
  font-weight: 400;
`;

export const BottomText = styled.p`
  font-size: 24px;
  line-height: 33.6px;
  font-weight: 400;
`;
