import styled from "styled-components";

export const HeaderContainer = styled.header`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const StyledFragment = styled.div`
    @media(max-width: 600px){
        display: none;
    }
`

export const UpBlock = styled.div`
  width: 80%;
  margin: 10px auto 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #010201;
`;

export const BottomBlock = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  margin-top: 15px;
  justify-content: space-around;
`;



export const HeaderBlock = styled.div`
  display: flex;
    @media(max-width: 600px){
        align-items: center;
    }
`;

export const LogoImg = styled.img`
  width: 72px;
  height: 72px;
    
    @media(max-width: 600px){
        width: 48px;
        height: 48px;
    }
`;

export const MiniImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 7px;
`;

export const ContactBlock = styled.div`
  display: flex;
  width: 215px;
`;

export const Text = styled.p`
  line-height: 10px;
  margin-block-start: 0;
  margin-top: 6px;
`;

export const ContactImgBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContactImgBlockSocial = styled.div`
  display: flex;
  justify-content: center;
    cursor: pointer;
    margin-right: 7px;
`;

export const ContactTextBlock = styled.div``;

export const AppointmentButton = styled.button`
  background: #5b8b0f;
  width: 140px;
  height: 40px;
  border: none;
  cursor: pointer;
  color: white;
`;

interface ToggleBlockProps {
  backgroundColor?: boolean;
}

export const LinkBlock = styled.div<ToggleBlockProps>`
    text-decoration: none;
    cursor: pointer;
    position: relative;
    background: ${({ backgroundColor }) => backgroundColor ?  '#5b8b0fa6' : 'white'};
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 6px 6px 0px 0px;
    

  a {
      margin: 5px;
      text-decoration: none;
      color: ${({ backgroundColor }) => backgroundColor ?  'white' : 'black'};
  }
`;

export const ToggleBlock = styled.div`
    position: absolute;
    top: 40px;
    background: #5b8b0fa6;
    z-index: 9;
    border-radius: 0px 6px 6px 6px;
    width: max-content;
`

export const ToggleText = styled.p`
    font-size: 16px;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;

    :hover {
        text-decoration: underline;
    }
`

export const MobileContainer = styled.div`
    display: none;
    height: 60px;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media(max-width: 600px){
        display: flex;
    }
`;

export const MobileBlockOpen = styled.div`
    width: 25px;
    height: 16px;
    cursor: pointer;
`;

export const MobileArrow = styled.div`
    width: 100%;
    height: 2px;
    background: #5b8b0f;
    margin-bottom: 5px;
`;

export const MobilePopUp = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(91, 139, 15, 0.9);
    z-index: 99999;
    top: 0;
    left: 0;
    overflowY: auto;
`;

export const MobileImageContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
`

export const CloseButton = styled.p`
    position: absolute;
    font-size: 30px;
    color: black;
    top: -10px;
    right: 25px;
`

export const MobileLinksContainer = styled.div`
    text-align: center;
    a {
        text-decoration: none;
        text-transform: uppercase;
    }
`;

export const MobileLinksText = styled.p`
    color: white;
`

export const ContactBlockMobile = styled.div`
    display: flex;
    color: #5b8b0f;
    max-width: 320px;
    font-weight: 600;
    width: 100%;
    font-size: 22px;
    margin-left: 5px;
`;
