import {
  RaspisanieContainer,
  RaspisanieImgWrapper,
  RaspisanieName,
  RaspisanieTitle
} from "../raspisanie/styled";
import {
  NameWrapperHight,
  RaspisanieSecondNameHight,
  SquareWrapperHight
} from "../raspisanieHight/styled";
import {Container} from "../Container/Container";
import {RaspisanieWrapperChild, RaspisanieImg} from "./styled";
import ras from "./raspis.png";
import {useEffect} from "react";

export const RaspisanieChild = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Container>
    <RaspisanieContainer>
      <RaspisanieTitle>Расписание Младших Классов</RaspisanieTitle>
      <RaspisanieWrapperChild>
        <NameWrapperHight>
          <RaspisanieName>Кабинет</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>104 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>202 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>201 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>206 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>Класс</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <div>
            <RaspisanieName>1 кл.</RaspisanieName>
            <RaspisanieName>О.А.</RaspisanieName>
          </div>
        </NameWrapperHight>
        <NameWrapperHight>
          <div>
            <RaspisanieName>2 кл.</RaspisanieName>
            <RaspisanieName>М.В.</RaspisanieName>
          </div>
        </NameWrapperHight>
        <NameWrapperHight>
          <div><RaspisanieName>3 кл.</RaspisanieName>
            <RaspisanieName>Ю.Д.</RaspisanieName></div>
        </NameWrapperHight>
        <NameWrapperHight>
          <div>
            <RaspisanieName>4 кл.</RaspisanieName>
            <RaspisanieName>Т.А.</RaspisanieName>
          </div>
        </NameWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>9:00 - 9:45</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Чтение</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Чтение</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Чтение</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Чтение</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>9:55 - 10:40</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Мир вокруг нас</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>10:55 - 11:40</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Музыка</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Подготовка в</RaspisanieSecondNameHight>
            <RaspisanieSecondNameHight>Secondaire</RaspisanieSecondNameHight>

          </div>
        </SquareWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>11:50 - 12:35</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Музыка</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Музыка</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Страноведение</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>12:45 - 13:30</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>

        </SquareWrapperHight>
        <SquareWrapperHight>

        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>

      </RaspisanieWrapperChild>
      <RaspisanieImgWrapper>
        <RaspisanieImg src={ras}/>
      </RaspisanieImgWrapper>
    </RaspisanieContainer>
  </Container>
}
