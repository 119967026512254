import {Container} from "../Container/Container";
import {CharityArr} from './../charity/CharityObj'
import {useLocation} from "react-router-dom";
import {CharityPageContainer, GridContainer, ImageContainer, MainImage} from "./styled";
import {HeadText} from "../Doctors/styled";
import {MainText} from "../dynamicPage/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/pagination";
import {SliderDynamicComponent, SliderImg} from "../dynamicPageStudies/styled";
import {useEffect} from "react";

type CharityKey = keyof typeof CharityArr;

export const CharityPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const key = location.pathname.split("/")[2] as CharityKey;

  const CharityObject = CharityArr[key]

  return <Container>
    <CharityPageContainer>
      <HeadText>{CharityObject.title}</HeadText>
      {CharityObject.photos.length > 0 && <SliderDynamicComponent>
        <Swiper
          pagination={{
            clickable: true
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 25000,
            disableOnInteraction: false
          }}>
          {CharityObject.photos.map((photo, index) => (<SwiperSlide>
            <SliderImg src={photo} />
          </SwiperSlide>))}
        </Swiper>
      </SliderDynamicComponent>}

      {CharityObject.photosv && <ImageContainer>
        <MainImage src={CharityObject.photosv}/>
      </ImageContainer>}

      {CharityObject.info.map((value: any) => {
        return <MainText>{value}</MainText>
      })}
      <GridContainer>
        {CharityObject.videos.map((url, index) => {
          const videoId = new URL(url).searchParams.get('v');
          return <iframe
            width="400"
            height="250"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={`YouTube Video ${index + 1}`}
          ></iframe>
        })}
      </GridContainer>
    </CharityPageContainer>
  </Container>
}
