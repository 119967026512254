import {
  ButtonBlock,
  ButtonContainer,
  DoctorBlock,
  DoctorImage,
  DoctorPhotos,
  DoctorsContainer,
  DoctorTable,
  DoctorText,
  HeadText,
  MainText
} from "./styled";
import psyhology from './../../images/teachers/psyhology.png'
import {routes} from "../../shared/routes/routes";
import {Link} from "react-router-dom";

export const DoctorsPsyh = () => {
  return (
    <DoctorsContainer>
      <HeadText>Психолог</HeadText>
      <DoctorBlock>
        <DoctorPhotos>
          <DoctorImage src={psyhology}/>
          <MainText>Наталия Викторовна Юрьева</MainText>
        </DoctorPhotos>

        <DoctorText>
          <DoctorTable>
            <MainText>Индивидуальные занятия:</MainText>
            <MainText>— выявление эмоциональных проблем: конфликтность, агрессивность, гиперактивность, медлительность, неспособность постоять за себя, импульсивность, склонность ко лжи и т.п.</MainText>
            <MainText>
              — коррекционная работа, направленная на решение определенных психологических проблем (работа со страхами, коррекция поведения, понижение уровня тревожности);
            </MainText>
            <MainText>
              — оказание психологической поддержки детям после серьезных травмирующих ситуаций (семейные проблемы, конфликты, военные действия и др.);
            </MainText>
            <ButtonBlock>
              <Link to={routes.lap}><ButtonContainer>Подробнее</ButtonContainer></Link>
            </ButtonBlock>
          </DoctorTable>
        </DoctorText>
      </DoctorBlock>
    </DoctorsContainer>
  );
};
