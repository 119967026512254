import {News} from "../News/News";
import {Container} from "../Container/Container";
import {useEffect} from "react";
import {NewsContainer, NewsTitle} from "../News/styled";
import {Link} from "react-router-dom";
import {New} from "../News/New/New";
import {useNews} from "../News/hook/useNews";
import {NewsBlock} from "./styled";

export const NewsPage = () => {
  const { news } = useNews();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Container>
    <NewsContainer>
      <NewsTitle>Новости школы</NewsTitle>
      <NewsBlock>
        {news && news.map((value: any) => {
          return <Link to={`/news/${value.slug}`}> <New key={value} props={value}/> </Link>
        })}
      </NewsBlock>
    </NewsContainer>
  </Container>
}
