import {CalendarTable, CalendarTd, CalendarTh, CalendarThBack, MonthHead} from "./../styled";

const highlightedDates = ["2024-10-05", "2024-10-12", "2024-10-20", "2024-10-26"];

export const October = () => {
  const daysInMonth = 31;
  const firstDayOfMonth = new Date(2024, 9, 1).getDay();
  const weeks = [];

  let dayCounter = 1;

  const isHighlighted = (day: any) => {
    const date = `2024-10-${String(day).padStart(2, '0')}`;
    return highlightedDates.includes(date);
  };

  for (let week = 0; week < 6; week++) {
    const days = [];
    for (let day = 0; day < 7; day++) {
      if (week === 0 && day < firstDayOfMonth) {
        days.push(<CalendarTd key={day}></CalendarTd>);
      } else if (dayCounter <= daysInMonth) {
        const isSpecialDay = isHighlighted(dayCounter);
        days.push(
          <CalendarTd key={day} as={isSpecialDay ? CalendarThBack : CalendarTd}>
            {dayCounter}
          </CalendarTd>
        );
        dayCounter++;
      } else {
        days.push(<CalendarTd key={day}></CalendarTd>);
      }
    }
    weeks.push(<tr key={week}>{days}</tr>);
  }

  return <div>
    <MonthHead>Октябрь</MonthHead>
    <CalendarTable className="calendar">
      <thead>
      <tr>
        <CalendarTh>Вс</CalendarTh>
        <CalendarTh>Пн</CalendarTh>
        <CalendarTh>Вт</CalendarTh>
        <CalendarTh>Ср</CalendarTh>
        <CalendarTh>Чт</CalendarTh>
        <CalendarTh>Пт</CalendarTh>
        <CalendarTh>Сб</CalendarTh>
      </tr>
      </thead>
      <tbody>
      {weeks}
      </tbody>
    </CalendarTable>
  </div>
}
