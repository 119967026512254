import styled from "styled-components";

export const LogopedandpsyhologyContainer = styled.div`
    max-width: 1380px;
    width: 100%;
    margin: 0 auto;
`

export const HeadText = styled.p`
  font-size: 32px;
    font-weight: 700;
    line-height: 23px;
    margin-top: 0px;
    color: #5B8B0F;
`

export const NameText = styled.p`
    font-size: 24px;
    margin-top: 5px;
    text-align: center;
`

export const MainText = styled.p`
    font-size: 18px;
`

export const MainHeadText = styled.p`
    font-size: 26px;
    margin-top: 45px;
    margin-bottom: 5px;
`

export const MainImage = styled.img`
    height: 420px;
    max-width: 375px;
    width: 100%;
    border: 6px solid #5B8B0F;
    @media(max-width: 600px){
        max-width: 310px;
        text-align: center;
        height: auto;
    }
`

export const TeacherContainer = styled.div`
    display: flex;
    margin-top: 50px;
    @media(max-width: 600px){
        display: block;
        padding: 15px;
        box-sizing: border-box;
    }
`

export const TeacherTextContainer = styled.div`
    margin-left: 20px;
    max-width: 1000px;
    width: 100%;
    @media(max-width: 1440px){
        max-width: 700px;
    }
`

export const TeacherImageContainer = styled.div`
    max-width: 390px;
    width: 100%;
    @media(max-width: 600px){
        text-align: center;
    }
`
