import styled from "styled-components";

export const SchoolBlock = styled.div``;

export const SchoolContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 90px;
`;

export const HeadText = styled.p`
  line-height: 67.2px;
  font-size: 48px;
  text-align: center;
  color: #5b8b0f;
  font-weight: 700;
    max-width: 1200px;
    margin: 0 auto;
    @media(max-width: 600px){
        font-size: 42px;
    }
`;
