import styled from "styled-components";

export const SecondorieContainer = styled.div`
    max-width: 1380px;
    width: 100%;
    margin: 0 auto;
    @media(max-width: 600px){
        padding-left: 10px;
        box-sizing: border-box;
    }
`

export const SecondorieWrapper = styled.div`
    //@media(max-width: 600px){
    //    padding: 17px;
    //}
`

export const MainText = styled.p`
  font-size: 20px;
  line-height: 30.2px;
  font-weight: 400;
    a {
        text-decoration: none;
    }
`;

export const MainSmall = styled.p`
  font-size: 17px;
  line-height: 20.2px;
  font-weight: 400;
`;


export const HeadText = styled.p`
  text-align: center;
  line-height: 50.4px;
  font-size: 36px;
  font-weight: 700;
  color: #5b8b0f;
`;

export const FirstContainer = styled.div`
    margin-top: 40px;
    width: 100%;
    max-width: 800px;
`

export const SecondHeadText = styled.p`
    font-size: 18px;
    font-weight: 700;
`

export const TeacherBlock = styled.div`
  border: 6px solid #5B8B0F;
  max-width: 780px;
    width: 100%;

    @media(max-width: 600px){
        border: none;
    }
`

export const TeacherBlockHight = styled.div`
    display: flex;
    @media(max-width: 600px){
        display: block;
    }
`

export const TeacherBlockBottom = styled.div`
    padding: 25px;
`

export const CircleBlock = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 20px;
    margin-top: 0px;
`

export const TeacherBlocksHight = styled.div`
    @media(max-width: 600px){
        padding: 10px;
        text-align: center;
    }
`
