import { useNews } from "./hook/useNews";
import { New } from "./New/New";
import { NewsBlock, NewsContainer, NewsTitle } from "./styled";
import { Link } from "react-router-dom";

export const News = () => {
  const { news } = useNews();

  return (
    <NewsContainer>
      <NewsTitle>Новости школы</NewsTitle>
      <NewsBlock>
        {news && news.slice(0, 5).map((value: any) => {
          return <Link to={`/news/${value.slug}`}> <New key={value} props={value}/> </Link>
        })}
      </NewsBlock>
    </NewsContainer>
  );
};
