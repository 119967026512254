import styled from "styled-components";

export const FooterContainer = styled.footer`
    width: 100%;
    height: 300px;
    background: #4e4e4e;
    margin-top: 50px;
    display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
    @media(max-width: 600px){
        height: 100%;
        grid-template-columns: 1fr;
    }
`

export const FooterBlocs = styled.div`
  margin: 0 auto;
    @media(max-width: 600px){
        height: 100%;
        grid-template-columns: 1fr;
        margin: 0px;
        margin-left: 20px;
    }
`

export const FooterTitle = styled.p`
    font-size: 32px;
    color: white;
`

export const FooterSecondText = styled.p`
    font-size: 32px;
    color: white;
`

export const ContactBlock = styled.div`
    display: flex;
    width: 215px;
    color: white;
    margin-top: 24px;
`;

export const MainText = styled.div`
    color: white;
    margin-top: 12px;
    :hover{
        text-decoration: underline;
    }
    a{
        text-decoration: none;
        color: white;
    }
`
