import {CalendarTable, CalendarTd, CalendarTh, CalendarThBack, MonthHead} from "./../styled";

export const November = () => {
  const daysInMonth = 30;
  const firstDayOfMonth = new Date(2024, 10, 1).getDay(); // 8 - это сентябрь (месяцы в JavaScript начинаются с 0)
  const weeks = [];

  let dayCounter = 1 - firstDayOfMonth;

  for (let week = 0; week < 6; week++) {
    const days = [];
    for (let day = 0; day < 7; day++) {
      if (dayCounter > 0 && dayCounter <= daysInMonth) {
        const isSaturday = day === 6;
        days.push(
          <CalendarTd key={day} as={isSaturday ? CalendarThBack : CalendarTd}>
            {dayCounter}
          </CalendarTd>
        );
      } else {
        days.push(<CalendarTd key={day}></CalendarTd>);
      }
      dayCounter++;
    }
    weeks.push(<tr key={week}>{days}</tr>);
  }

  return <div>
    <MonthHead>Ноябрь</MonthHead>
    <CalendarTable className="calendar">
      <thead>
      <tr>
        <CalendarTh>Вс</CalendarTh>
        <CalendarTh>Пн</CalendarTh>
        <CalendarTh>Вт</CalendarTh>
        <CalendarTh>Ср</CalendarTh>
        <CalendarTh>Чт</CalendarTh>
        <CalendarTh>Пт</CalendarTh>
        <CalendarTh>Сб</CalendarTh>
      </tr>
      </thead>
      <tbody>
      {weeks}
      </tbody>
    </CalendarTable>
  </div>
}
