import styled from "styled-components";

export const StudiesContainer = styled.div`
  margin-top: 90px;
`;

export const StudiesBlock = styled.div``;

export const HeadText = styled.p`
  line-height: 67.2px;
  font-size: 48px;
  text-align: center;
  color: #5b8b0f;
  font-weight: 700;
    @media(max-width: 600px){
        margin-top: 60px;
    }
`;

export const StudiesBlockAround = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  a {
   text-decoration: none; 
}

    @media(max-width: 600px){
        grid-template-columns: 1fr;
    }
    
`;
