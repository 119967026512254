import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/pagination";

import { HeadText } from "../School/styled";
import {
  DynamicPageContainer,
  MainText,
  SliderDynamicComponent,
  SliderImg
} from "./styled";

import { Container } from "../Container/Container";
import {useLocation} from "react-router-dom";
import {useStudie} from "./hook/useStudie";
import {useEffect} from "react";

export const DynamicPageStudies = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const location = useLocation();
    const {studie, isLoading} = useStudie({slug: location.pathname.split("/")[2]})

  if(!isLoading && studie){
    return (
      <Container>
        <DynamicPageContainer>
          <HeadText>{studie.title}</HeadText>

          <SliderDynamicComponent>
            <Swiper
              pagination={{
                clickable: true
              }}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 25000,
                disableOnInteraction: false
              }}>
              <SwiperSlide>
                <SliderImg src={studie.image} />
              </SwiperSlide>
              <SwiperSlide>
                <SliderImg src={studie.image} />
              </SwiperSlide>
              <SwiperSlide>
                <SliderImg src={studie.image} />
              </SwiperSlide>
            </Swiper>
          </SliderDynamicComponent>
          <MainText>
            {studie.description[0]}
          </MainText>
          {studie.description.slice(1, studie.description.length).map((value: any) => (<MainText>{value}</MainText>))}

        </DynamicPageContainer>
      </Container>
    );
  }
  return <div>Loading....</div>
};
