import {Container} from "../Container/Container";
import {
  HeadText,
  LogopedandpsyhologyContainer, MainHeadText,
  MainImage,
  MainText, NameText,
  TeacherContainer, TeacherImageContainer,
  TeacherTextContainer
} from "./styled";
import logoped from './../../images/teachers/4b6775301903a92109c71064cdfd553d.png'
import psyholody from './../../images/teachers/psyhology.png'
import {useEffect} from "react";

export const Logopedandpsyhology = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Container>
    <LogopedandpsyhologyContainer>
      <TeacherContainer>
        <TeacherImageContainer>
          <MainImage src={logoped}/>
          <NameText>Татьяна Александровна</NameText>
        </TeacherImageContainer>
        <TeacherTextContainer>
          <HeadText>Логопед</HeadText>
          <MainHeadText>Индивидуальные занятия:</MainHeadText>
          <MainText>— коррекция звукопроизношения, нарушений лексико-грамматического строя речи, слоговой структуры слова;</MainText>
          <MainText>— развитие фонематического восприятия, внимания, памяти, связной речи, мелкой моторики, речевого дыхания;</MainText>
          <MainText>— формирование словесно-логического мышления;</MainText>
          <MainText>— обогащение словарного запаса.</MainText>
          <MainHeadText>Образование:</MainHeadText>
          <MainText>— Московский Государственный Педагогический Университет, диплом магистра (с отличием). Специальное дефектологическое образование. Профиль: Образование детей с ограниченными возможностями здоровья.</MainText>
          <MainText>— Московский Государственный Педагогический Университет, диплом бакалавра. Специальное (дефектологическое) образование. Профиль: логопедия.</MainText>
          <MainHeadText>Квалификация:</MainHeadText>
          <MainText>— Учитель-логопед высшей квалификационной категории</MainText>
          <MainText>— Участник межвузовских научных конференций молодых ученых по дефектологии «Инновационные проблемы современного развития дефектологии» Московский Педагогический Государственный Университет. 2012 г, 2017 г</MainText>
          <MainText>— Опыт работы логопедом и дефектологом в детских дошкольных учреждениях с 2014 г (гор. Москва)</MainText>
          <MainText>— Вела научную работу в отделе модельной практики, диагностики и коррекции нарушений развития у детей при Институте Коррекционной Педагогики Российской Академии Образования 2018г. (гор. Москва).</MainText>
        </TeacherTextContainer>
      </TeacherContainer>
      <TeacherContainer>
        <TeacherImageContainer>
          <MainImage src={psyholody}/>
          <NameText>Наталья Викторовна</NameText>
        </TeacherImageContainer>
        <TeacherTextContainer>
          <HeadText>Психолог</HeadText>
          <MainHeadText>Индивидуальные занятия:</MainHeadText>
          <MainText>— психологическая диагностика ребенка с использованием
            современных психодиагностических методов (определяем
            психологические особенности личности ребенка, которые
            непосредственно влияют на его эмоциональное состояние);</MainText>
          <MainText>— выявление эмоциональных проблем: конфликтность, агрессивность,
            гиперактивность, медлительность, неспособность постоять за себя,
            импульсивность, склонность ко лжи и т.п.</MainText>
          <MainText>— коррекционная работа, направленная на решение определенных
            психологических проблем (работа со страхами, коррекция поведения,
            понижение уровня тревожности);</MainText>
          <MainText>— оказание психологической поддержки детям после серьезных
            травмирующих ситуаций (семейные проблемы, конфликты, военные
            действия и др.);</MainText>
          <MainHeadText>Групповые занятия:</MainHeadText>
          <MainText>— психологическое и эмоциональное развитие ребенка (адаптация в
            новом коллективе, мотивация к учебе, коммуникация со сверстниками);</MainText>
          <MainText>— укрепление психического здоровья (эмоциональная разгрузка,
            современные арт-методики и сказкотерапия).</MainText>
          <MainHeadText>Образование:</MainHeadText>
          <MainText>— Ростовский-на Дону государственный педагогический институт,
            Российская Федерация, диплом специалиста (1988-1993).
            Специальность «Педагогика и психология (дошкольная)».
            Квалификация: педагог педагогики и психологии (дошкольной), методист
            дошкольного образования, преподаватель английского языка в детском
            саду.</MainText>
          <MainText>— Национальный университет гражданской защиты государственной
            службы Украины по чрезвычайным ситуациям, аспирантура (2012-2015).
            Специальность «Психология деятельности в особых условиях».</MainText>
          <MainHeadText>Квалификация:</MainHeadText>
          <MainText>— Кандидат психологических наук (доктор философии)</MainText>
          <MainText>— Участник более 40 международных научных и научно-практических
            конференциях и семинарах в области психологии (изучение аспектов
            морально-психологического состояния личности; влияние экстремальных
            условий на профессиональную деятельность; мотивация к учебной
            деятельности; особенности адаптации в коллективах; психологическая
            помощь при потере одного из родителей; коррекция поведения; работа со
            страхами и т.п.)</MainText>
          <MainText>— Автор 3 монографий, 3 научно-исследовательских работ, 9 учебных
            пособий, более 30 научных статей в научных и профессиональных
            отечественных и зарубежных изданиях, в том числе, под редакцией Scopus и
            Web of Science.</MainText>
          <MainText>— Работа в научно-исследовательском центре морально-психологического
            обеспечения деятельности (2014-2022): исследование психологических
            состояний личности в экстремальных условиях, организация
            психологической помощи на всех этапах и с учетом специфики деятельности,
            организация и проведение научно-практических конференций и семинаров,
            организация и проведение практических занятий на курсах повышения
            квалификации психологов.</MainText>
          <MainText>— Организация и обеспечение учебного процесса кафедры военного искусства
            (2000-2014). Планирование и проведение занятий с дисциплин «Прикладная
            психология», «Общая психология».</MainText>
          <MainText>— Опыт работы воспитателем в детских дошкольных учреждениях (1989-
            1998). Организация и проведение спортивных занятий, турниров с детьми
            дошкольного возраста.</MainText>
        </TeacherTextContainer>
      </TeacherContainer>
    </LogopedandpsyhologyContainer>
  </Container>
}
