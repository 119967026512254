import styled from "styled-components";

export const DoctorsContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
`;

export const DoctorBlock = styled.div`
  display: flex;
    @media(max-width: 600px){
        display: block;
    }
`;

export const DoctorPhotos = styled.div`
    @media(max-width: 600px){
        text-align: center;
    }
`;

export const DoctorImage = styled.img`
  width: 320px;
  height: 360px;
  margin-right: 40px;
    @media(max-width: 600px){
        margin-right: 0px;
    }
`;

export const DoctorTable = styled.div`
  margin-top: 70px;
    @media(max-width: 600px){
        margin-top: 10px;
    }
`;

export const DoctorText = styled.div`
  margin-top: -15px;
    @media(max-width: 600px){
        margin-top: 0px;
    }
`;

export const MainText = styled.p`
  font-size: 20px;
  line-height: 30.2px;
  font-weight: 400;
`;

export const HeadText = styled.p`
  text-align: center;
  line-height: 50.4px;
  font-size: 36px;
  font-weight: 700;
  color: #5b8b0f;
`;

export const ButtonBlock = styled.div`
  display: flex;
  justify-content: left;
    a{
        text-decoration: none;
    }
`;

export const ButtonContainer = styled.div`
  cursor: pointer;
  background: #5b8b0f;
  width: 140px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
