import styled from "styled-components";

export const AppointmentContainer = styled.div`
  text-align: center;
  max-width: 1380px;
    margin: 0 auto;
`

export const AppointmentWrapper = styled.div`
    @media(max-width: 600px){
        padding: 0px 20px 0px 10px;
    }
`

export const HeadText = styled.p`
    font-size: 26px;
`

export const MainText = styled.p`
    text-align: left;
`

export const MainForm = styled.form`
  text-align: left;
`

export const MainInput = styled.input`
    display: block;
    margin-top: 15px;
    width: 100%;
    max-width: 600px;
    height: 26px;
    font-size: 18px;
    border: 2px solid #5b8b0f;
`

export const LableAppointmentContainer = styled.label`
    width: 100%;
    max-width: 600px;
    border: 2px solid #5b8b0f;
`

export const SelectAppointmentContainer = styled.select`
    width: 100%;
    max-width: 468px;
    border: 2px solid #5b8b0f;
    text-align: left;
`

export const AppointmentButton = styled.button`
  background: #5b8b0f;
  width: 140px;
  height: 40px;
  border: none;
  cursor: pointer;
  color: white;
    display: block;
    margin-top: 15px;
`;

export const BlueSpan = styled.span`
    color: #0000ff;
`

export const GreenSpan = styled.span`
    color: #5b8b0f;
    cursor: pointer;
`

export const ErrorText = styled.p`
    color: #fd5b5b;
    font-size: 13px;
    text-align: left;
    line-height: 1px;
`

export const SuccessText = styled.p`
    color: #42af42;
    font-size: 13px;
    text-align: left;
    line-height: 1px;
`
