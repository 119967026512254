import {ImageBlock, InfoBlockContainer, InfoText, StudiesImg} from "./styled";

export const InfoBlock = ({name, img}: {name: string, img: string}) => {
  return (
    <InfoBlockContainer>
      <ImageBlock>
        <StudiesImg src={img}/>
      </ImageBlock>
      <InfoText>{name}</InfoText>
    </InfoBlockContainer>
  );
};
