import {
  ButtonBlock,
  ButtonContainer,
  DoctorBlock,
  DoctorImage,
  DoctorPhotos,
  DoctorsContainer,
  DoctorTable,
  DoctorText,
  HeadText,
  MainText
} from "./styled";
import logoped from './../../images/teachers/4b6775301903a92109c71064cdfd553d.png'
import {Link} from "react-router-dom";
import {routes} from "../../shared/routes/routes";

export const Doctors = () => {
  return (
    <DoctorsContainer>
      <HeadText>Логопед</HeadText>
      <DoctorBlock>
        <DoctorPhotos>
          <DoctorImage src={logoped}/>
          <MainText>Татьяна Александровна Бокарева</MainText>
        </DoctorPhotos>

        <DoctorText>
          <MainText>
            Диагностика общего психологического развития ребенка.
          </MainText>
          <DoctorTable>
            <MainText>Индивидуальные занятия:</MainText>
            <MainText>— развитие познавательных процессов ребенка;</MainText>
            <MainText>
              — улучшение коммуникационных навыков и самооценки;
            </MainText>
            <MainText>
              — обеспечение успешной адаптации школьников к обществу;
            </MainText>
            <MainText>— поддержание психологического здоровья.</MainText>
            <ButtonBlock>
              <Link to={routes.lap}><ButtonContainer>Подробнее</ButtonContainer></Link>
            </ButtonBlock>
          </DoctorTable>
        </DoctorText>
      </DoctorBlock>
    </DoctorsContainer>
  );
};
