import { AboutUsImageContainer, AboutUsImageText, AboutUsImg } from "./styled"
import onas from './../../../images/o_nas.jpg'
export const ABoutUsImage = () => {
    return <AboutUsImageContainer>
        <AboutUsImageText>«И если я живу на свете,<br />
То только лишь из-за МЕЧТЫ»<br />
(Н.Гумилев)</AboutUsImageText>
        <AboutUsImg src={onas}/>
    </AboutUsImageContainer>
}
