import styled from "styled-components";

export const InfoBlock = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
    @media(max-width: 600px){
        display: block;
        margin-top: 30px;
    }
`;

export const ImageBlock = styled.div`
  border: 10px solid #5b8b0f;
  max-width: 813px;
    width: 100%;
  height: 518px;
    margin-right: 15px;
    @media(max-width: 600px){
        max-width: 600px;
        height: 260px;
        margin-right: 0px;
        border: 5px solid #5b8b0f;
    }
`;

export const TextBlock = styled.div`
  border: 10px solid #5b8b0f;
  max-width: 500px;
    width: 100%;
  height: 329px;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #010201;
  padding: 80px;
    
  @media (max-width: 1300px) {
      font-size: 14px;
      padding: 0px;
      padding-top: 34px;
  }

    @media(max-width: 600px){
        margin-top: 50px;
        border: 5px solid #5b8b0f;
    }
`;

export const MobileText = styled.div`
    @media(max-width: 600px){
        padding: 10px;
    }
`

export const HeadText = styled.div`
  position: absolute;
  background: white;
  color: #010201;
  font-size: 48px;
  line-height: 67.2px;
  font-weight: 400;
  top: -35px;
    max-width: 365px;
    width: 100%;
    
    @media(max-width: 600px){
        margin-top: 50px;
        max-width: 200px;
        font-size: 25px;
        line-height: 47.2px;
        top: -80px;
        left: 15px;
    }
`;
