import {ButtonContainer, ImgContainer, NewContainer, NewText} from "../News/New/styled";

export const CharityBlock = (props: any) => {

  return <NewContainer>
    <ImgContainer src={props.photo}/>
    <NewText>{props.title}</NewText>
    <ButtonContainer>Подробнее</ButtonContainer>
  </NewContainer>
}
