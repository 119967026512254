import styled from "styled-components";

export const InfoBlockContainer = styled.div`
    width: fit-content;
    margin: 50px auto 0px;
`;

export const ImageBlock = styled.div`
  padding: 10px;
  position: relative;
  width: 350px;
  height: 250px;
  margin-left: 10px;
  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 50%;
    border-bottom: 5px solid #5b8b0f;
  }
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    height: 50%;
    border-left: 5px solid #5b8b0f;
  }

    @media(max-width: 600px){
        width: 280px;
        height: 180px;
    }
`;

export const InfoText = styled.p`
  color: #7aa13c;
  line-height: 28px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
    max-width: 280px;
    margin: 0 auto;
`;

export const StudiesImg = styled.img`
    width: 350px;
    height: 250px;
    @media(max-width: 600px){
        width: 280px;
        height: 180px;
    }
`
