import styled from "styled-components";

export const AboutUsImageContainer = styled.div`
    display: flex;
    font-style:italic;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    justify-content: space-between;
    margin-top: 35px;
    margin-bottom: 65px;
    @media(max-width: 600px){
        display: block;
    }
`

export const AboutUsImageText = styled.p``

export const AboutUsImg = styled.img`
    max-width: 382px;
    width: 100%;
    height: 229px;
    @media(max-width: 600px){
        height: auto;
    }
`
