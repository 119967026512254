import styled from "styled-components";

export const NewsContainer = styled.div`
    width: 90%;
    margin: 0 auto;
    
    a {
        text-decoration: none;
    }
    @media(max-width: 600px){
        a {
            margin: 0 auto;
        }
    }
`

export const NewsTitle = styled.p`
    text-align: center;
    font-size:48px;
    font-weight:400;
    color: #010201;
`

export const NewsBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    @media(max-width: 600px){
        grid-template-columns: 1fr;
        gap: 30px;
    }
`
