import {
  AppointmentButton,
  BottomBlock,
  CloseButton,
  ContactBlock, ContactBlockMobile,
  ContactImgBlock,
  ContactTextBlock,
  HeaderBlock,
  HeaderContainer,
  LogoImg,
  MiniImg,
  MobileArrow,
  MobileBlockOpen,
  MobileContainer,
  MobileImageContainer,
  MobileLinksContainer, MobileLinksText,
  MobilePopUp,
  StyledFragment,
  Text,
  UpBlock
} from "./styled";
import logo from "./../../images/mechta_logo.png";
import point from "./../../images/point-images/point.png";
import phone from "./../../images/point-images/phone.png";
import mail from "./../../images/point-images/mail.png";
import { routes } from "../../shared/routes/routes";
import { Link } from "react-router-dom";
import {LinkBlockComponent} from "./LinkBlockComponent";
import {useState} from "react";

const Links = [
  {
    name: "О НАС",
    route: routes.about,
    toggle: false,
    routes: []
  },
  {
    name: "ПРЕДМЕТЫ И СТУДИИ",
    route: '/',
    toggle: true,
    routes: [{
      name: "Подготовка в Secondorie",
      link: routes.secondorie
    },
      {
        name: "Школа, студии и кружки",
        link:routes.studiesAnd
      },
      {
        name: "Предметы",
        link:routes.subjects
      } ]
  },
  {
    name: "НОВОСТИ",
    route: routes.onlyNews,
    toggle: false,
    routes: []
  },
  {
    name: "КАЛЕНДАРЬ",
    route: routes.calendar,
    toggle: false,
    routes: []
  },
  {
    name: "РАСПИСАНИЕ",
    route: "/",
    toggle: true,
    routes: [{
      name: "Расписание студий и кружков",
      link: routes.raspisanie
    }, {
      name: "Расписание средних и старших классов",
      link: routes.raspisanieHight
    },
      {
        name: "Расписание младших классов",
        link: routes.raspisanieChild
      },
      {
        name: "Расписание подготовительных классов",
        link: routes.raspisaniePodgotovka
      },

      ]
  },
  {
    name: "ЛОГОПЕД И ПСИХОЛОГ",
    route: routes.lap,
    toggle: false,
    routes: []
  },
  {
    name: "БЛАГОТВОРИТЕЛЬНОСТЬ",
    route: routes.charity,
    toggle: false,
    routes: []
  }
];

export const Header = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <HeaderContainer>
      <StyledFragment>
        <UpBlock>
          <HeaderBlock>
            <Link to={routes.index}>
              <LogoImg src={logo} />
            </Link>
          </HeaderBlock>
          <HeaderBlock>
            <ContactBlock>
              <ContactImgBlock>
                <MiniImg src={point} />
              </ContactImgBlock>
              <ContactTextBlock>
                4245 Decarie Blvd, Montreal, QC H4A 3K4
              </ContactTextBlock>
            </ContactBlock>
            <ContactBlock>
              <ContactImgBlock>
                <MiniImg src={phone} />
              </ContactImgBlock>
              <ContactTextBlock>
                <Text>+1(514)772-50-12</Text>
                <Text>+1(514)795-46-77</Text>
                <Text>+1(514)712-50-12</Text>
              </ContactTextBlock>
            </ContactBlock>
            <ContactBlock>
              <ContactImgBlock>
                <MiniImg src={mail} />
              </ContactImgBlock>
              <ContactTextBlock>director@mechtacenter.org</ContactTextBlock>
            </ContactBlock>
          </HeaderBlock>
          <HeaderBlock>
            <Link to={routes.appointment} >
            <AppointmentButton>Запись в школу</AppointmentButton>
            </Link>
          </HeaderBlock>
        </UpBlock>
        <BottomBlock>
          {Links.map((value) => {
            return (
              <LinkBlockComponent name={value.name} route={value.route} toggle={value.toggle} routes={value.routes}/>
            );
          })}
        </BottomBlock>
      </StyledFragment>
      <MobileContainer>
        <HeaderBlock>
          <Link to={routes.index}>
            <LogoImg src={logo} />
          </Link>
          <ContactBlockMobile>
            <ContactTextBlock>Школа "Мечта"</ContactTextBlock>
          </ContactBlockMobile>
        </HeaderBlock>
        <MobileBlockOpen onClick={() => setToggle(!toggle)}>
          <MobileArrow/>
          <MobileArrow/>
          <MobileArrow/>
        </MobileBlockOpen>
        {toggle && <MobilePopUp>
          <CloseButton onClick={() => setToggle(!toggle)}>X</CloseButton>
          <MobileImageContainer><LogoImg src={logo} /></MobileImageContainer>
          <MobileLinksContainer>
            {[...Links, {
              name: "ЗАПИСЬ В ШКОЛУ",
              route: routes.appointment,
              toggle: false,
              routes: []
            },{
              name: "Расписание студий и кружков",
              route: routes.raspisanie,
              toggle: false,
              routes: []
            }, {
              name: "Расписание средних и старших классов",
              route: routes.raspisanieHight,
              toggle: false,
              routes: []
            },
              {
                name: "Расписание младших классов",
                route: routes.raspisanieChild,
                toggle: false,
                routes: []
              },
              {
                name: "Расписание подготовительных классов",
                route: routes.raspisaniePodgotovka,
                toggle: false,
                routes: []
              },
              {
                name: "Подготовка в Secondorie",
                route: routes.secondorie,
                toggle: false,
                routes: []
              },
              {
                name: "Школа, студии и кружки",
                route:routes.studiesAnd,
                toggle: false,
                routes: []
              },
              {
                name: "Предметы",
                route:routes.subjects,
                toggle: false,
                routes: []
              }].map((value) => {
              return  value.route !== '/' && <Link to={value.route}><MobileLinksText>{value.name}</MobileLinksText></Link>
            })}

          </MobileLinksContainer>
        </MobilePopUp>}


      </MobileContainer>
    </HeaderContainer>
  );
};
