import {HeadText, ImageBlock, InfoBlock, MobileText, TextBlock} from "./styled";
import mechta from "./../../images/mechta.png";

export const Info = () => {
  return (
    <InfoBlock>
      <ImageBlock
        style={{
          background: `url(${mechta}) center center / cover`
        }}></ImageBlock>
      <TextBlock>
        <HeadText>&#8203; &#8203; Школа “Мечта”</HeadText>
        <MobileText>
          Основная цель деятельности Детского Центра «Мечта» — сохранение на
          территории Канады русского языка и русской культуры, развитие
          любознательности, умения самостоятельно ориентироваться в потоке
          современной информации. Воспитание таких качеств, как понимание и
          уважение культурных традиций, истории Квебека и Канады, также является
          важной задачей Центра.
        </MobileText>
      </TextBlock>
    </InfoBlock>
  );
};
