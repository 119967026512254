import {
  AppointmentContainer,
  HeadText,
  MainForm,
  MainInput,
  MainText,
  AppointmentButton,
  BlueSpan,
  GreenSpan, ErrorText, SuccessText, LableAppointmentContainer, SelectAppointmentContainer, AppointmentWrapper
} from "./styled";
import {Container} from "../Container/Container";
import {useForm} from "react-hook-form";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {useEffect, useState} from "react";

const schema = yup.object().shape({
  fioChild: yup.string().required("Пожалуйста, введите фамилию и имя ребенка"),
  fioChildFr: yup.string().required("Пожалуйста, введите имя ребенка на французском"),
  class: yup.string().required("Пожалуйста, введите класс ребенка"),
  fullAge: yup
    .number()
    .typeError("Пожалуйста, введите количество полных лет")
    .required("Пожалуйста, введите количество полных лет")
    .positive("Возраст должен быть положительным числом")
    .integer("Возраст должен быть целым числом"),
  fioParent: yup.string().required("Пожалуйста, введите ваши Ф.И.О."),
  date: yup
    .date()
    .required("Пожалуйста, выберите дату")
    .min(new Date(2000, 0, 1), "Дата должна быть не раньше 1 января 2000 года")
    .max(new Date(), "Дата не может быть в будущем"),
  telephone: yup
    .string()
    .required("Пожалуйста, введите ваш телефон")
    .matches(/^\d+$/, "Телефон должен содержать только цифры"),
  email: yup
    .string()
    .required("Пожалуйста, введите адрес электронной почты")
    .email("Некорректный адрес электронной почты"),
});

export const Appointment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [success, setSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value === 'true');
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const appointmentFunc = async (data: any) => {
    try{
      await axios.post("https://work.markin.studio/api/v1/post/form-back/object", {
        childFrenchname: data.fioChildFr,
        childName: data.fioChild,
        phone: data.telephone,
        birthday: data.date,
        fullAge: data.fullAge + "",
        classSchool: data.class,
        fio: data.fioParent,
        email: data.email,
        wasCheck: selectedOption
      })
      setSuccess(true)
    }catch(error){}

  }

  const onSubmit = (data:any) => {
    appointmentFunc(data)
  }

  return <Container>
    <AppointmentContainer>
      <AppointmentWrapper>
      <HeadText>Регистрация учеников «Мечты» на 2024-2025 учебный год</HeadText>
      <MainText>Детский Центр «Мечта» продолжает онлайн регистрацию на новый, <BlueSpan> 2024-2025, учебный год.</BlueSpan> С 1 июня регистрационный взнос —  <BlueSpan> 60 долларов.</BlueSpan></MainText>
      <MainText>Заполните форму и оплатите регистрационный взнос.</MainText>
      <MainText>Оплата производится путём <BlueSpan> Envoyer un Virement Interac</BlueSpan> по телефону <BlueSpan> 514-772-5012 </BlueSpan>или по электронной почте: <GreenSpan> sitnikova.mechta@gmail.com</GreenSpan>
        </MainText>
      <MainText>Ответ на вопрос для электронного перевода: <BlueSpan>Mechta</BlueSpan></MainText>

      <MainForm onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="cars">Учились в школе?  </label>
        <SelectAppointmentContainer name="cars" id="cars" onChange={handleChange}>
          <option value="false">нет</option>
          <option value="true">да</option>
        </SelectAppointmentContainer>
        <MainInput placeholder="Фамилия и имя ребенка" {...register('fioChild')}/>
        {errors.fioChild && <ErrorText>{errors.fioChild.message}</ErrorText>}
        <MainInput placeholder="Nom et prénom" {...register('fioChildFr')}/>
        {errors.fioChildFr && <ErrorText>{errors.fioChildFr.message}</ErrorText>}
        <MainInput placeholder="Класс, который он(а) посещал(а) в 2023-2024 учебном году" {...register('class')}/>
        {errors.class && <ErrorText>{errors.class.message}</ErrorText>}
        <MainInput placeholder="кол-во полных лет" type="number" {...register('fullAge')}/>
        {errors.fullAge && <ErrorText>{errors.fullAge.message}</ErrorText>}
        <MainInput placeholder="кол-во полных лет" type="date" {...register('date')}/>
        {errors.date && <ErrorText>{errors.date.message}</ErrorText>}
        <MainInput placeholder="Ваши Ф.И.О" {...register('fioParent')}/>
        {errors.fioParent && <ErrorText>{errors.fioParent.message}</ErrorText>}
        <MainInput placeholder="Ваш телефон" type="number" {...register('telephone')}/>
        {errors.telephone && <ErrorText>{errors.telephone.message}</ErrorText>}
        <MainInput placeholder="Адресс электронной почты" type="email" {...register('email')}/>
        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
        <AppointmentButton type="submit">Запись</AppointmentButton>
        {success && <SuccessText>Форма записи успешно отправлена</SuccessText>}
      </MainForm>
      </AppointmentWrapper>
    </AppointmentContainer>
  </Container>
}
