import {Container} from "../Container/Container";
import {CharityContaoner, CharityGridContainer, CharityText, CharityWrapper, MainText, SecondText} from "./styled";
import {CharityArr} from "./CharityObj";
import {CharityBlock} from "./CharityBlock";
import {Link} from "react-router-dom";
import {useEffect} from "react";

export const Charity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Container>
    <CharityWrapper>
      <CharityContaoner>
      <MainText>Благотворительность</MainText>
      <CharityText>Центр ежегодно проводит благотворительные вечера. Полученные средства идут на благотворительные цели. Благодаря  фонду «Дети — наше будущее»,  Детский центр «Мечта» является учебной организацией, в которой:</CharityText>
      <CharityText>- лучшие ученики образовательной школы получают стипендии;</CharityText>
      <CharityText>- все ученики 6-11 классов  получают бесплатно дополнительный урок (факультативный час) по выбору: математика, физика, химия, литература;</CharityText>
      <CharityText>- работают бесплатные студии: хоровая - для всех детей, а театральная и танцевальная - для детей средней и старшей школы;</CharityText>
      <CharityText>- семьи, которые оказались в трудной финансовой ситуации, получают дополнительные льготы.</CharityText>
      <CharityText>- особые условия обучения для детей из Украины.</CharityText>
      <SecondText>Ученикам русской школы «Мечта» предоставляются скидки на обучение в частных школах Монреаля:</SecondText>
      <CharityText><a href="https://villamaria.qc.ca/en/" target="_blank">Villa Maria</a> — 10%</CharityText>
      <CharityText><a href="https://www.collegefrancais.ca/" target="_blank">Collège Françai</a> —
        20%</CharityText>
      <CharityText><a href="https://www.premiereslettres.com/" target="_blank">L’École des Premières Lettres</a> —
        30%</CharityText>

      <MainText>Благотворительные вечера прошлых лет</MainText>
      </CharityContaoner>
      <CharityGridContainer>
        {Object.entries(CharityArr).map(([key, value]) => {
          return <Link to={`/charity/${key}`}> <CharityBlock photo={value.photo} title={value.title} key={value.photo}/></Link>
        })}
      </CharityGridContainer>

    </CharityWrapper>
  </Container>
}
