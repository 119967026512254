import styled from "styled-components";

export const CalendarContainer = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 40px;
    @media(max-width: 600px){
        grid-template-columns: 1fr;
    }
`

export const CalendarTable = styled.table`
width: 100%;`

export const CalendarWrapper = styled.div`
    width: 100%;
    border-collapse: collapse;
`

export const CalendarTh = styled.th`
    padding: 8px;
    text-align: center;
    color: #5b8b0f;
`

export const CalendarThBack = styled.th`
    padding: 8px;
    text-align: center;
    background: #5b8b0f;
`

export const CalendarTd = styled.td`
    padding: 8px;
    text-align: center;
`


const CalendarTdstandart = styled(CalendarTd)`
  background-color: #ffeb3b; 
`;

export const MonthHead = styled.p`
    font-size: 18px;
    font-weight: 700;
    color:  #5b8b0f;
    margin-left: 20px
`


