import styled from "styled-components";

export const SliderWrapper = styled.div`
    @media(max-width: 600px){
        display: none;
    }
`

export const SliderBlock = styled.div`
  max-width: 1920px;
  width: 100%;
  height: 610px;
  display: flex;
  margin-top: 50px;
`;

export const SliderImg = styled.div`
  width: 100%;
  height: 100%;
`;

export const UpBlock = styled.div`
  margin-top: 120px;
  margin-left: 180px;
  a {
    text-decoration: none;
}  
`;

export const UpTextBlock = styled.div`
  background: #33333380;
  width: 680px;
  height: fit-content;
  font-weight: 400;
  line-height: 56px;
  font-size: 42px;
  color: white;
  padding-left: 15px;
`;

export const UpBlockButton = styled.div`
  width: 170px;
  height: 58px;
  font-size: 20px;
  line-height: 28px;
  background: #5b8b0f;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
`;
