import {Container} from "../Container/Container";
import {KryjkiWrapper} from "./styled";
import {StudiesBlock, StudiesBlockAround} from "../Studies/styled";
import {Link} from "react-router-dom";
import {InfoBlock} from "../InfoBlock/InfoBlock";
import {useStudies} from "../Studies/useStudies";
import {HeadText} from "../School/styled";
import {schoolArr} from "../Studies/ourSchoolObj";
import {useEffect} from "react";

export const Kryjki = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {studies} = useStudies()

  return <Container>
    <KryjkiWrapper>
      <HeadText>Наша школа</HeadText>
      <StudiesBlockAround>
        {Object.entries(schoolArr).map(([key, value]) => {
          return <Link to={`/ourschool/${value.link}`}> <InfoBlock name={value.title} img={value.photo}/></Link>
        })}
      </StudiesBlockAround>
      <HeadText>Студии и кружки</HeadText>
      <StudiesBlock>
        <StudiesBlockAround>
          {studies && studies.map((value: any) => {
            return <Link to={`/class/${value.slug}`}> <InfoBlock name={value.title} img={value.image}/></Link>
          })}
        </StudiesBlockAround>

      </StudiesBlock>
    </KryjkiWrapper>
  </Container>
}
