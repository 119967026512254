import {Container} from "../Container/Container";
import {MapContainer, MapText, MapWrapper} from "./styled";

export const Map = () => {
  return <Container>
    <MapContainer>
      <MapWrapper>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11189.656236055429!2d-73.6167957!3d45.4816061!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc910ab28cc1c77%3A0x5e4c28211dba0ef8!2z0JTQtdGC0YHQutC40Lkg0KbQtdC90YLRgCDQnNC10YfRgtCwINCg0YPRgdGB0LrQsNGPINGI0LrQvtC70LA!5e0!3m2!1sru!2sca!4v1721454022412!5m2!1sru!2sca"
        width="1100" height="450" loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
      </MapWrapper>
      <MapText>
        На територии школы бесплатная парковка
      </MapText>
    </MapContainer>
  </Container>
}
