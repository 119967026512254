import styled from "styled-components";

export const CharityPageContainer = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
`

export const MainImage = styled.img`
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
`
export const ImageContainer = styled.div`
    display: flex;
`

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr
    
`
