import styled from "styled-components";

export const DynamicPageContainer = styled.div``;

export const MainText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: black;
  max-width: 1000px;
    width: 100%;
  margin: 15px auto;
    @media(max-width: 600px){
        padding: 15px;
        box-sizing: border-box;
    }
`;

export const SliderDynamicComponent = styled.div`
  max-width: 1000px;
    width: 100%;
  height: 670px;
  margin: 15px auto;
    @media(max-width: 600px){
        height: auto;
    }

  .swiper{
      border-radius: 20px;
  }  
`;

export const SliderImg = styled.img`
  width: 100%;
  height: 670px;
    @media(max-width: 600px){
        height: auto;
    }
`;

export const VideoContainer = styled.div`
    margin: 0 auto;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    
`
