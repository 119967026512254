import styled from "styled-components";

export const RaspisanieContainer = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
`

export const RaspisanieWrapper = styled.div`
    width: 857px;
    margin: 50px auto;
    display: grid;
    display: none;
    grid-template-columns: 120px repeat(7, 100px);
    gap: 5px;
    grid-auto-rows: 70px;
    @media(max-width: 600px){
        grid-template-columns: 60px repeat(7, 50px);
        grid-auto-rows: 50px;
        gap: 1px;
    }
`

export const RaspisanieName = styled.p`
    font-size: 13px;
    font-weight: 700;
    line-height: 12px;
`

export const RaspisanieNameWrapper = styled.p`
    font-size: 13px;
    font-weight: 700;
    line-height: 6px;
`

export const RaspisanieThirdName = styled.p`
    font-size: 13px;
    font-weight: 600;
    line-height: 12px;
`

export const RaspisanieSecondName = styled.p`
    font-size: 12px;
    color: #585858;
    line-height: 1px;
`

export const SquareWrapper = styled.div`
    border: 3px solid #A0B085;
    text-align: center;
`;

export const NameWrapper = styled.div`
    border: 3px solid #5B8B0F;
    text-align: center;
`

export const RaspisanieTitle = styled.h2`
    text-align: center;
    color: #5b8b0f;
    margin-top: 60px;
`

export const RaspisanieImgWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const RaspisanieImg = styled.img`
    max-width: 857px;
    width: 100%;
    margin: 0 auto;
`
