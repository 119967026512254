import {CalendarContainer, CalendarTd, CalendarTh, CalendarThBack} from "./styled";
import {Container} from "../Container/Container";
import {September} from "./months/September";
import {October} from "./months/October";
import {November} from "./months/November";
import {December} from "./months/December";
import {January} from "./months/January";
import {February} from "./months/February";
import {March} from "./months/March";
import {April} from "./months/April";
import {May} from "./months/May";
import {useEffect} from "react";


export const Calendar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return <Container>
    <CalendarContainer>
      <September />
      <October />
      <November />
      <December />
      <January />
      <February />
      <March />
      <April />
      <May />
    </CalendarContainer>
  </Container>
}
