import {ContactBlock, FooterBlocs, FooterContainer, FooterSecondText, FooterTitle, MainText} from "./styled";
import point from "./../../images/point-images/point.png";
import phone from "./../../images/point-images/phone.png";
import mail from "./../../images/point-images/mail.png";
import inst from "./../../images/social/inst.png";
import fb from "./../../images/social/fb.png";
import tg from "./../../images/social/tg.png";
import {ContactImgBlock, ContactImgBlockSocial, ContactTextBlock, MiniImg, Text} from "../header/styled";
import {Link} from "react-router-dom";
import {routes} from './../../shared/routes/routes'

export const Footer = () => {
  return <FooterContainer>
    <FooterBlocs>
      <FooterTitle>Школа "Мечта"</FooterTitle>
      <ContactBlock>
        <ContactImgBlock>
          <MiniImg src={point} />
        </ContactImgBlock>
        <ContactTextBlock>
          4245 Decarie Blvd, Montreal, QC H4A 3K4
        </ContactTextBlock>
      </ContactBlock>

      <ContactBlock>
        <ContactImgBlockSocial>
          <a href="https://www.instagram.com/mechtamtl" target="_blank">
          <MiniImg src={inst} />
          </a>
        </ContactImgBlockSocial>
        <ContactImgBlockSocial>
          <a href="https://www.facebook.com/centermechta/" target="_blank">
          <MiniImg src={fb}/>
          </a>
        </ContactImgBlockSocial>
        <ContactImgBlockSocial>
          <a href="https://t.me/mechtamtl" target="_blank">
          <MiniImg src={tg}/>
          </a>
        </ContactImgBlockSocial>
      </ContactBlock>

    </FooterBlocs>
    <FooterBlocs>
      <FooterSecondText>О нас</FooterSecondText>
      <MainText>
        <Link to={routes.about}>
        Наша история
        </Link>
      </MainText>
      <MainText>
        <Link to={routes.onlyNews}>
        Новости
        </Link>
      </MainText>
      <MainText><Link to={routes.studiesAnd}>
        Студии и кружки
      </Link></MainText>
      <MainText><Link to={routes.lap}>
        Психолог и Логопед
      </Link></MainText>
    </FooterBlocs>
    <FooterBlocs>
      <FooterSecondText>Расписание</FooterSecondText>
      <MainText>
        <Link to={routes.raspisaniePodgotovka}>
          Подготовительные классы
        </Link>
        </MainText>
      <MainText>
        <Link to={routes.raspisanieChild}>
          Начальная школа
        </Link></MainText>
      <MainText>
        <Link to={routes.raspisanieHight}>
          Средняя и старшая школа
        </Link>
      </MainText>
      <MainText>
        <Link to={routes.raspisanie}>
          Расписание студий
        </Link>
      </MainText>
    </FooterBlocs>
    <FooterBlocs>
      <FooterSecondText>Контакты</FooterSecondText>
      <ContactBlock>
        <ContactImgBlock>
          <MiniImg src={mail} />
        </ContactImgBlock>
        <ContactTextBlock>director@mechtacenter.org</ContactTextBlock>
      </ContactBlock>
      <ContactBlock>
        <ContactImgBlock>
          <MiniImg src={phone} />
        </ContactImgBlock>
        <ContactTextBlock>
          <Text>+1(514)772-50-12</Text>
          <Text>+1(514)795-46-77</Text>
          <Text>+1(514)712-50-12</Text>
        </ContactTextBlock>
      </ContactBlock>
    </FooterBlocs>
  </FooterContainer>
}
