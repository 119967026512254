import {Container} from "../Container/Container";
import {
  RaspisanieContainer, RaspisanieImgWrapper,
  RaspisanieName,
  RaspisanieTitle,
} from "../raspisanie/styled";
import {NameWrapperHight, RaspisanieSecondNameHight, RaspisanieWrapperHight, SquareWrapperHight, RaspisanieImg} from "./styled";
import ras from "./raspis.png";
import {useEffect} from "react";

export const RaspisanieHight = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Container>
    <RaspisanieContainer>
      <RaspisanieTitle>Расписание Старших Классов</RaspisanieTitle>
      <RaspisanieWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>Кабинет</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>202 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>201 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>225 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>226 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>227 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>228 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>228 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>Класс</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
         <div>
           <RaspisanieName>5 кл.</RaspisanieName>
           <RaspisanieName>Н.В.</RaspisanieName>
         </div>
        </NameWrapperHight>
        <NameWrapperHight>
         <div>
           <RaspisanieName>6 кл.</RaspisanieName>
           <RaspisanieName>О.Н.</RaspisanieName>
         </div>
        </NameWrapperHight>
        <NameWrapperHight>
          <div><RaspisanieName>7 кл.</RaspisanieName>
            <RaspisanieName>Е.Р.</RaspisanieName></div>
        </NameWrapperHight>
        <NameWrapperHight>
         <div>
           <RaspisanieName>8 кл.</RaspisanieName>
           <RaspisanieName>О.В.</RaspisanieName>
         </div>
        </NameWrapperHight>
        <NameWrapperHight>
         <div> <RaspisanieName>9 кл.</RaspisanieName>
           <RaspisanieName>О.Н.</RaspisanieName></div>
        </NameWrapperHight>
        <NameWrapperHight>
          <div>
            <RaspisanieName>10 кл.</RaspisanieName>
            <RaspisanieName>О.И.</RaspisanieName>
          </div>
        </NameWrapperHight>
        <NameWrapperHight>
          <div>
            <RaspisanieName>11 кл.</RaspisanieName>
            <RaspisanieName>А.Р.</RaspisanieName>
          </div>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>9:00 - 9:45</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Страноведение</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight> <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight></SquareWrapperHight>
        <SquareWrapperHight> <RaspisanieSecondNameHight>Алгебра</RaspisanieSecondNameHight></SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Биология</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight> <RaspisanieSecondNameHight>Биология</RaspisanieSecondNameHight></SquareWrapperHight>
        <SquareWrapperHight> <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight></SquareWrapperHight>
        <SquareWrapperHight> <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight></SquareWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>9:55 - 10:40</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Литература</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Геометрия</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Химия</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Химия</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Литература</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Литература</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>10:55 - 11:40</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Физика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Биология</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Биология</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>11:50 - 12:35</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Литература</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Русский язык</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Физика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Химия</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Химия</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>12:45 - 13:30</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Литература</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Страноведение</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Биология</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Алгебра</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Литература</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Физика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Физика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>13:40 - 14:25</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight></SquareWrapperHight>
        <SquareWrapperHight></SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Физика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Геометрия</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Алгебра</RaspisanieSecondNameHight>

          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Алгебра</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Алгебра</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>14:30 - 15:15</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight></SquareWrapperHight>
        <SquareWrapperHight></SquareWrapperHight>
        <SquareWrapperHight></SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Литература</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Геометрия</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Геометрия</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Геометрия</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
      </RaspisanieWrapperHight>
      <RaspisanieImgWrapper>
        <RaspisanieImg src={ras}/>
      </RaspisanieImgWrapper>
    </RaspisanieContainer>
  </Container>
}
