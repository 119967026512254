import {Link} from "react-router-dom";
import {ToggleBlock, ToggleText, LinkBlock} from "./styled";
import {useState} from "react";

export const LinkBlockComponent = (props: any) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return <LinkBlock key={props.name} backgroundColor={props.toggle && toggleMenu} onMouseEnter={() => props.toggle && setToggleMenu(!toggleMenu)} onMouseLeave={() => props.toggle && setToggleMenu(!toggleMenu)}>
    <Link to={props.route} >{props.name}</Link>
    {props.toggle && toggleMenu && props.routes &&  <ToggleBlock>
      {
        props.routes.map((value: any) => {
          return  <ToggleText><Link to={value.link}>{value.name}</Link></ToggleText>
        })
      }
    </ToggleBlock>}
  </LinkBlock>
}
