import {
  AdvantagesBlock,
  AdvantagesBlocks,
  AdvantagesContainer,
  AdvantagesText,
  BottomText,
  BottomTextBlock,
  CursorText,
  HeadText,
  ImageBlock,
  TextBlock
} from "./styled";
import first from "./../../images/advantages-img/first.png";
import second from "./../../images/advantages-img/second.png";
import third from "./../../images/advantages-img/third.png";

export const Advantages = () => {
  return (
    <AdvantagesBlock>
      <HeadText>Наши преимущества</HeadText>
      <AdvantagesContainer>
        <AdvantagesBlocks>
          <ImageBlock style={{ background: `url(${first})` }} />
          <AdvantagesText>
            ПОДГОТОВКА К ПОСТУПЛЕНИЮ В ПРЕСТИЖНЫЕ ЧАСТНЫЕ ШКОЛЫ
          </AdvantagesText>
        </AdvantagesBlocks>
        <AdvantagesBlocks>
          <ImageBlock style={{ background: `url(${second})` }} />
          <AdvantagesText>СКИДКИ В ЧАСТНЫЕ ШКОЛЫ</AdvantagesText>
        </AdvantagesBlocks>
        <AdvantagesBlocks>
          <ImageBlock style={{ background: `url(${third})` }} />
          <AdvantagesText>СТИПЕНДИИ ЛУЧШИМ УЧАЩИМСЯ</AdvantagesText>
        </AdvantagesBlocks>
      </AdvantagesContainer>
      {/*<BottomTextBlock>*/}
      {/*  <TextBlock>*/}
      {/*    <CursorText>*/}
      {/*      Ошибочно думать, что много знание есть достоинство. Важно не*/}
      {/*      количество, а качество знания.{" "}*/}
      {/*    </CursorText>{" "}*/}
      {/*    <BottomText>*/}
      {/*      Лев Николаевич Толстой(1828–1910) русский писатель, мыслитель,*/}
      {/*      просветитель*/}
      {/*    </BottomText>*/}
      {/*  </TextBlock>*/}
      {/*</BottomTextBlock>*/}
    </AdvantagesBlock>
  );
};
