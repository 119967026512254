import styled from "styled-components";

export const AboutUsContainer = styled.div`
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
`

export const HeadContent = styled.div`
    display: flex;
    @media(max-width: 600px){
        display: block;
    }
`

export const ImageBlock = styled.div`
    @media(max-width: 600px){
        display: flex;
        img {
            margin: 0 auto;
    }
    }
`

export const TextBlock = styled.div``

export const AboutHead = styled.p`
    font-size: 26px;
    color: #000000;
    font-weight: 700;
    margin-top: 80px;
    line-height: 33.6px;
`

export const MainTextBlock = styled.div``;

export const AboutText = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: black;
    margin-top: 20px;
`

export const GreenBlock = styled.div`
    border: 5px solid #5B8B0F;
    padding: 25px 45px 25px 45px;
    font-size: 18px;
    font-weight: 600;
`
