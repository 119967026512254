import { ButtonContainer, ImgContainer, NewContainer, NewText } from "./styled"

export const New = ({props}: any) => {

    const cleanedText = props.description.replace(/https?:\/\/\S+/g, '').trim();

    return <NewContainer>
        <ImgContainer src={props.image}/>
        <NewText>{cleanedText.length > 120 ? cleanedText.slice(0, 120) + "..." : cleanedText}</NewText>
        <ButtonContainer>Подробнее</ButtonContainer>
    </NewContainer>
}
