import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { DynamicPage } from "./components/dynamicPage/DynamicPage";
import { AboutUs } from "./pages/AboutUs/AboutUs";
import { Main } from "./pages/Main/Main";
import { routes } from "./shared/routes/routes";
import {Map} from "./components/Map/Map";
import {Appointment} from "./components/Appointment/Appointment";
import {NewsPage} from "./components/NewsPage/NewsPage";
import {Logopedandpsyhology} from "./components/logopedandpsyhology/Logopedandpsyhology";
import {Secondorie} from "./components/secondorie/Secondorie";
import {Calendar} from "./components/calendar/Calendar";
import {Charity} from "./components/charity/Charity";
import {DynamicPageStudies} from "./components/dynamicPageStudies/DynamicPageStudies";
import {Raspisanie} from "./components/raspisanie/Raspisanie";
import {Subjects} from "./components/Subjects/Subjects";
import {Kryjki} from "./components/Kryjki/Kryjki";
import {CharityPage} from "./components/CharityPage/CharityPage";
import {OurSchool} from "./components/OurSchool/OurSchool";
import {OurSchoolSlug} from "./components/OurSchoolSlug/OurSchoolSlug";
import {RaspisanieHight} from "./components/raspisanieHight/RaspisanieHight";
import {RaspisanieChild} from "./components/raspisanieChild/RaspisanieChild";
import {RaspisaniePodgotovka} from "./components/raspisaniePodgotovka/RaspisaniePodgotovka";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path={routes.index} element={<Main />} />
          <Route path={routes.about} element={<AboutUs />} />
          <Route path={routes.class} element={<DynamicPageStudies />} />
          <Route path={routes.news} element={<DynamicPage />} />
          <Route path={routes.map} element={<Map />} />
          <Route path={routes.appointment} element={<Appointment />} />
          <Route path={routes.onlyNews} element={<NewsPage />} />
          <Route path={routes.lap} element={<Logopedandpsyhology />} />
          <Route path={routes.secondorie} element={<Secondorie />} />
          <Route path={routes.calendar} element={<Calendar />} />
          <Route path={routes.charity} element={<Charity />} />
          <Route path={routes.raspisanie} element={<Raspisanie />} />
          <Route path={routes.subjects} element={<Subjects />} />
          <Route path={routes.studiesAnd} element={<Kryjki />} />
          <Route path={routes.charitySlug} element={<CharityPage />} />
          <Route path={routes.ourSchool} element={<OurSchool />} />
          <Route path={routes.ourSchoolSlug} element={<OurSchoolSlug />} />
          <Route path={routes.raspisanieHight} element={<RaspisanieHight />} />
          <Route path={routes.raspisanieChild} element={<RaspisanieChild />} />
          <Route path={routes.raspisaniePodgotovka} element={<RaspisaniePodgotovka />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
