import {Container} from "../Container/Container";
import {
  NameWrapper,
  RaspisanieContainer, RaspisanieImg, RaspisanieImgWrapper,
  RaspisanieName, RaspisanieNameWrapper, RaspisanieSecondName, RaspisanieThirdName,
  RaspisanieTitle,
  RaspisanieWrapper,
  SquareWrapper
} from "./styled";
import ras from './raspis.png'
import {useEffect} from "react";

export const Raspisanie = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Container>
    <RaspisanieContainer>
      <RaspisanieTitle>Расписание студий и кружков</RaspisanieTitle>
      <RaspisanieWrapper>
        <NameWrapper>
          <RaspisanieName>Изо</RaspisanieName>
          <RaspisanieSecondName>404 кабинет</RaspisanieSecondName>
        </NameWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>11:45 - 12:40</RaspisanieThirdName>
          <RaspisanieSecondName>0, 0-1 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>12:45 - 13:40</RaspisanieThirdName>
          <RaspisanieSecondName>0-1, 1-2 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>13:45 - 14:40</RaspisanieThirdName>
          <RaspisanieSecondName>1-2 и 3-6 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>14:40 - 15:35</RaspisanieThirdName>
          <RaspisanieSecondName>3-4 и 5-6 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>15:40 - 16:40</RaspisanieThirdName>
          <RaspisanieSecondName>ст. кл.</RaspisanieSecondName>
        </SquareWrapper>
        <NameWrapper>
          <RaspisanieName>Танцы</RaspisanieName>
          <RaspisanieSecondName>Le Chappele</RaspisanieSecondName>
        </NameWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>11:45 - 12:40</RaspisanieThirdName>
          <RaspisanieSecondName>0, 0-1 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>12:45 - 13:40</RaspisanieThirdName>
          <RaspisanieSecondName>0-1, 1-2 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>13:50 - 14:45</RaspisanieThirdName>
          <RaspisanieSecondName>1-2, 3-6 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>15:40 - 16:40</RaspisanieThirdName>
          <RaspisanieSecondName>ст. кл.</RaspisanieSecondName>
        </SquareWrapper>
        <NameWrapper>
          <RaspisanieName>Суперкроха</RaspisanieName>
        </NameWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>9:00—10:30</RaspisanieThirdName>
          <RaspisanieSecondName>первая группа</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>13:00—14:30</RaspisanieThirdName>
          <RaspisanieSecondName>вторая группа</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <NameWrapper>
          <RaspisanieName>Хор</RaspisanieName>
          <RaspisanieSecondName>206 кабинет</RaspisanieSecondName>
        </NameWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper> <RaspisanieThirdName>13:45 - 14:30</RaspisanieThirdName></SquareWrapper>
        <SquareWrapper> <RaspisanieThirdName>14:45 - 15:30</RaspisanieThirdName></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <NameWrapper>
          <RaspisanieName>Шахматы</RaspisanieName>
          <RaspisanieSecondName>104 кабинет</RaspisanieSecondName>
        </NameWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>12:45—13:40</RaspisanieThirdName>
          <RaspisanieSecondName>1-2 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>14:45—15:40</RaspisanieThirdName>
          <RaspisanieSecondName>3-5 и 6-7 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>15:40—16:40</RaspisanieThirdName>
          <RaspisanieSecondName>ст. классы</RaspisanieSecondName>
        </SquareWrapper>
        <NameWrapper>
          <RaspisanieName>Английский язык</RaspisanieName>
          <RaspisanieSecondName>102 кабинет</RaspisanieSecondName>
        </NameWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>12:45—13:40</RaspisanieThirdName>
          <RaspisanieSecondName>1-2 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>13:45—14:40</RaspisanieThirdName>
          <RaspisanieSecondName>3-4 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>14:45—15:40</RaspisanieThirdName>
          <RaspisanieSecondName>4-5 кл.</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>15:45—16:40</RaspisanieThirdName>
          <RaspisanieSecondName>ст. кл.</RaspisanieSecondName>
          <RaspisanieSecondName>(или онлайн)</RaspisanieSecondName>
        </SquareWrapper>
        <NameWrapper>
          <RaspisanieName>Робототехника</RaspisanieName>
          {/*<RaspisanieSecondName>106 кабинет</RaspisanieSecondName>*/}
        </NameWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>12:45—13:40</RaspisanieThirdName>
          <RaspisanieSecondName>1-2 классы</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>13:45—14:40</RaspisanieThirdName>
          <RaspisanieSecondName>3-6 классы</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieThirdName>14:45—15:40</RaspisanieThirdName>
          <RaspisanieSecondName>5-7 классы</RaspisanieSecondName>
        </SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <NameWrapper>
          <RaspisanieName>Подготовка к Secondaire</RaspisanieName>
          <RaspisanieSecondName>Онлайн</RaspisanieSecondName>
        </NameWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper>
          <RaspisanieSecondName>Среда 5 кл.</RaspisanieSecondName>
          <RaspisanieNameWrapper>17:30—18:15</RaspisanieNameWrapper>
          <RaspisanieNameWrapper>18:20—19:05</RaspisanieNameWrapper>
        </SquareWrapper>
        <SquareWrapper>
          <RaspisanieSecondName>Среда 6 кл.</RaspisanieSecondName>
          <RaspisanieNameWrapper>19:15—20:00</RaspisanieNameWrapper>
          <RaspisanieNameWrapper>20:05—20:50</RaspisanieNameWrapper>
        </SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
        <SquareWrapper></SquareWrapper>
      </RaspisanieWrapper>
      <RaspisanieImgWrapper>
        <RaspisanieImg src={ras}/>
      </RaspisanieImgWrapper>
    </RaspisanieContainer>
  </Container>
}
