import {
  AboutHead,
  AboutText,
  AboutUsContainer,
  GreenBlock,
  HeadContent,
  ImageBlock,
  MainTextBlock,
  TextBlock
} from "./styled";
import mechta from "./../../images/mecta_about.png";
import { ABoutUsImage } from "./AboutUsImage/AboutUsImage";
import { Container } from "../../components/Container/Container";
import {useEffect} from "react";

export const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <AboutUsContainer>
        <HeadContent>
          <TextBlock>
            <AboutHead>
              Детский Центр «Мечта» — это единственная на территории Канады
              общественная организация образовательного профиля, открыт с 2001
              года.
            </AboutHead>
          </TextBlock>
          <ImageBlock>
            <img src={mechta} />
          </ImageBlock>
        </HeadContent>
        <MainTextBlock>
          <AboutText>
            Деятельность Центра многократно отмечена Премьер-Министрами Канады,
            Министерствами Иммиграции Канады и Квебека, Мэрией города Монреаля.
            Детский Центр «Мечта» является членом Международного Педагогического
            Общеста в поддержку русского языка и русской культуры, членом МАПРЯЛ
            (международная ассоциация преподавателей русского языка и
            литературы).
          </AboutText>
          <AboutText>
            Детский Центр «Мечта» стал инициатором проведения Первой
            Всеканадской педагогической конференции в поддержку русского языка и
            русской культуры при содействии Посольства России в Канаде и КСРСК.
            Генеральное Консульство России в Монреале отметило 15-летнюю работу
            Центра Почетной грамотой.
          </AboutText>
          <AboutText>
            Детский Центр «Мечта» — это русская школа с уникальным центром
            досуга и творчества, где ребята могут проводить время по интересам в
            многочисленных кружках и секциях. Ежегодно в Центре проводятся
            предметные олимпиады. Победители награждаются дипломами, грамотами и
            денежными призами.
          </AboutText>
          <AboutText>
            Это единственная русская образовательная организация на территории
            Канады, которая имеет свой благотворительный фонд «Дети — наше
            будущее». Благодаря этому, в старших классах в расписании введён
            один бесплатный урок, где ребята по своему выбору могут получить
            репетиторскую поддержку по математике, физике, химии, что дает им
            огромное преимущество при поступлении в колледж. Также благодаря
            Фонду, лучшие ученики старшей школы в конце года получают стипендии
            в виде денежных чеков.
          </AboutText>
          <AboutText>
            Кроме того, для всех детей Центра открыта бесплатная хоровая студия,
            а для детей средней и старшей школы работают бесплатно театральная и
            танцевальная студии.
          </AboutText>
          <GreenBlock>
            Основная цель деятельности Детского Центра «Мечта» — сохранение на
            территории Канады русского языка и русской культуры, развитие
            любознательности, умения самостоятельно ориентироваться в потоке
            современной информации. Воспитание таких качеств, как понимание и
            уважение культурных традиций, истории Квебека и Канады, также
            является важной задачей Центра.
          </GreenBlock>
          <ABoutUsImage />
          <AboutText>
            Детский Центр «Мечта» работает с февраля 2001 года как общественная
            некоммерческая организация для детей и подростков от 1.5 до 17 лет.
          </AboutText>
          <AboutText>
            В марте 2006 года Детский Центр «Мечта» получил в l’Agence du Revenu
            du Canada (ARC) статус организации, имеющей право на получение
            благотворительной помощи.
          </AboutText>
          <AboutText>
            Учредители Центра — Ситникова Татьяна, Кононова Татьяна и Сиднева
            Алла являются также ведущими преподавателями школы. За плечами
            каждой — богатейший опыт педагогической работы. У них учатся, с ними
            советуются. Они сумели сплотить вокруг себя замечательный коллектив
            педагогов-единомышленников, который способствует сохранению русской
            культуры и русских традиций на канадской земле.
          </AboutText>
          <AboutText>
            За многолетний период плодотворной работы у Центра появились свои
            замечательные традиции: Рождественские праздники, ежегодные
            концерты, посвященные Международному Женскому Дню и концу учебного
            года, отчетные концерты музыкальной школы, спектакли театральной
            студии, предметные Олимпиады, выставки работ художественной студии и
            Благотворительные вечера «Дети — наше будущее».
          </AboutText>
        </MainTextBlock>
      </AboutUsContainer>
    </Container>
  );
};
