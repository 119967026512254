export const routes = {
  index: "/",
  about: "/about",
  class: "/class/:slug",
  map: '/map',
  appointment: '/appointment',
  news: '/news/:slug',
  onlyNews: '/news',
  secondorie: '/secondorie',
  lap: '/speechandpsychologist',
  calendar: '/calendar',
  charity: '/charity',
  raspisanie: '/raspisanie',
  subjects: '/subjects',
  studiesAnd: "/studies",
  charitySlug: "/charity/:slug",
  ourSchool: "/ourschool",
  ourSchoolSlug: "/ourschool/:slug",
  raspisanieHight: '/raspisanieHight',
  raspisanieChild: '/raspisanieChild',
  raspisaniePodgotovka: '/raspisaniePodgotovka'
};
