import styled from "styled-components";

export const RaspisanieWrapperHight = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 50px auto;
    display: grid;
    display: none;
    grid-template-columns: repeat(8, 120px);
    gap: 5px;
    grid-auto-rows: 70px;
`;

export const NameWrapperHight = styled.div`
    border: 3px solid #5B8B0F;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SquareWrapperHight = styled.div`
    border: 3px solid #A0B085;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RaspisanieSecondNameHight = styled.p`
    font-size: 12px;
    color: #363636;
    line-height: 1px;
`

export const RaspisanieImg = styled.img`
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
`
