import styled from "styled-components";

export const CharityWrapper = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    @media(max-width: 600px){
        padding: 15px;
        box-sizing: border-box;
    }
`

export const CharityContaoner = styled.div`
    padding-left: 10px;
`

export const CharityGridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    a{
    text-decoration: none;
}
    @media(max-width: 600px){
        grid-template-columns: 1fr;
        a{
            margin: 0 auto;
        }
    }
`


export const MainText = styled.p`
  font-size: 26px;
  line-height: 30.2px;
  font-weight: 400;
`;

export const SecondText = styled.p`
  font-size: 22px;
  line-height: 30.2px;
  font-weight: 400;
`;

export const CharityText = styled.p`
    font-size: 18px;
    
    a{
        text-decoration: none;
        color: #5b8b0f;
    }
`
