import {RaspisanieContainer, RaspisanieImgWrapper, RaspisanieName, RaspisanieTitle} from "../raspisanie/styled";
import {
  NameWrapperHight,
  RaspisanieSecondNameHight,
  SquareWrapperHight
} from "../raspisanieHight/styled";
import {Container} from "../Container/Container";
import {RaspisanieWrapperPodgotovka, RaspisanieImg} from "./styled";
import ras from "./rasp.png";
import {useEffect} from "react";

export const RaspisaniePodgotovka = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Container>
    <RaspisanieContainer>
      <RaspisanieTitle>Расписание Подготовительных Классов</RaspisanieTitle>
      <RaspisanieWrapperPodgotovka>
        <NameWrapperHight>
          <RaspisanieName>Кабинет</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>106 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>101 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>102 каб.</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <RaspisanieName>Класс</RaspisanieName>
        </NameWrapperHight>
        <NameWrapperHight>
          <div>
            <RaspisanieName>0-A кл.</RaspisanieName>
            <RaspisanieName>Ан.В.</RaspisanieName>
          </div>
        </NameWrapperHight>
        <NameWrapperHight>
          <div>
            <RaspisanieName>0-Б кл.</RaspisanieName>
            <RaspisanieName>Ал.В.</RaspisanieName>
          </div>
        </NameWrapperHight>
        <NameWrapperHight>
          <div>
            <RaspisanieName>0-1 кл.</RaspisanieName>
            <RaspisanieName>И.А.</RaspisanieName>
          </div>
        </NameWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>8:30 - 9:00</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Развитие речи</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Развитие речи</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Развитие речи</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>9:10 - 9:40</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Грамота</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Грамота</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Грамота</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>9:50 - 10:20</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Музыка</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Музыка</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>10:30 - 11:00</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Мы растем</RaspisanieSecondNameHight>
            <RaspisanieSecondNameHight>здоровыми</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Мы растем</RaspisanieSecondNameHight>
            <RaspisanieSecondNameHight>здоровыми</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Мы растем</RaspisanieSecondNameHight>
            <RaspisanieSecondNameHight>здоровыми</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>


        <NameWrapperHight>
          <RaspisanieName>11:10 - 11:40</RaspisanieName>
        </NameWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Математика</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>
        <SquareWrapperHight>
          <div>
            <RaspisanieSecondNameHight>Музыка</RaspisanieSecondNameHight>
          </div>
        </SquareWrapperHight>

      </RaspisanieWrapperPodgotovka>
      <RaspisanieImgWrapper>
        <RaspisanieImg src={ras}/>
      </RaspisanieImgWrapper>
    </RaspisanieContainer>
  </Container>
}
