import styled from "styled-components";

export const RaspisanieWrapperChild = styled.div`
    max-width: 600px;
    width: 100%;
    margin: 50px auto;
    display: grid;
    display: none;
    grid-template-columns: repeat(5, 120px);
    gap: 5px;
    grid-auto-rows: 70px;
`;

export const RaspisanieImg = styled.img`
    max-width: 610px;
    width: 100%;
    margin: 0 auto;
`
