import styled from "styled-components";

export const MapContainer = styled.div`

`

export const MapWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 70px;
    iframe {
        border: none;
`

export const MapText = styled.p`
    font-size: 24px;
    text-align:  center;
`
