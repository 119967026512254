import styled from "styled-components";

export const NewContainer = styled.div`
    width: 235px;
    text-decoration: none!important;
`;

export const ImgContainer = styled.img`
    width: 235px;
    height: 150px;
`;

export const DateLine = styled.p`
  font-size: 14px;
  font-weight:400;
  color:#585858;
`

export const NewText = styled.p`
    font-size: 16px;
    font-weight:400;
    color: #000000;
    text-decoration: none;
`

export const ButtonContainer = styled.div`
  cursor: pointer;
  background: #5b8b0f;
  width: 140px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
