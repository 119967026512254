import {Container} from "../Container/Container";
import {SubjectImage, SubjectInfo, SubjectsContainer, SubjectWrapper, SubjectWrapperContainer} from "./styled";
import mat from './assets/mathema.jpg'
import ball from './assets/ball.jpeg'
import biol from './assets/biol.jpg'
import russ from './assets/russ.jpg'
import geogr from './assets/geogr.jpg'
import {HeadText} from "./styled";
import {useEffect} from "react";

export const Subjects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Container>
    <SubjectsContainer>
      <SubjectWrapper>
        <SubjectImage src={mat}/>
        <SubjectWrapperContainer>
          <HeadText>Математика</HeadText>
          <SubjectInfo>Программа обеспечивает ребятам глубокие знания и, как следствие, очень высокие  результаты в местной школе. Математические термины даются на двух языках – русском и французском. Все ученики  5 — 11 классов получают бесплатно дополнительные уроки по математике.</SubjectInfo>
          <SubjectInfo>В старших классах ученики обучаются по углубленной программе, в которую входят элементы арифметики, алгебры, начал математического анализа, евклидовой геометрии плоскости и пространства, аналитической геометрии и тригонометрии. Обучение математике направлено на овладение учащимися системой математических знаний, умений и навыков, необходимых для дальнейшего изучения математики и смежных учебных предметов, решения практических задач, на развитие логического мышления, пространственного воображения, устной и письменной математической речи, формирование навыков вычислений, алгебраических преобразований решения уравнений и неравенств, инструментальных и графических навыков.</SubjectInfo>
          <SubjectInfo>Наши программы, оптимально сочетая лучшие стороны русских и канадских программ, ориентированы на местные престижные школы и колледжи.</SubjectInfo>
        </SubjectWrapperContainer>
      </SubjectWrapper>
      <SubjectWrapper>
        <SubjectImage src={biol}/>
        <SubjectWrapperContainer>
          <HeadText>Биология</HeadText>
          <SubjectInfo>Все самые любопытные стороны живой природы мы изучаем здесь, на уроках биологии в русской школе «Мечта»!</SubjectInfo>
          <SubjectInfo>Преподавание ведётся по современным программам и учебным пособиям. После каждого урока ребята получают опорные конспекты по пройденной теме. Каждый конспект содержит теоретический материал с иллюстрациями, схемами, таблицами и т.д. В конце каждого конспекта имеется небольшое домашнее задание, которое позволяет нашим детям закрепить изученный на уроке материал. Также к уроку  прилагаются ссылки на небольшие, но интересные видеоролики и презентации.</SubjectInfo>
        </SubjectWrapperContainer>
      </SubjectWrapper>
      <SubjectWrapper>
        <SubjectImage src={geogr}/>
        <SubjectWrapperContainer>
          <HeadText>География</HeadText>
          <SubjectInfo>Все науки на Земле начались с любознательности человека. Именно она заставила его искать ответы на многие вопросы. С любопытства зародилась и география на заре становления человеческой цивилизации. Она сопровождала человеческое общество во все периоды его развития. Этот предмет – увлекательное путешествие по нашей планете.</SubjectInfo>
          <SubjectInfo>Ученики изучают особенности Земли как планеты Солнечной системы.  Получают ответы на многие вопросы: почему и как происходят землетрясения и извержения вулканов, почему в океанах вода соленая, а в реках пресная, как возникают приливы и волны цунами, как образуется ветер и осадки.</SubjectInfo>
          <SubjectInfo></SubjectInfo>
        </SubjectWrapperContainer>
      </SubjectWrapper>
      <SubjectWrapper>
        <SubjectImage src={ball}/>
        <SubjectWrapperContainer>
          <HeadText>Физика и химия</HeadText>
          <SubjectInfo>Почему тает лёд, падает камень, как распространяется свет в воздухе, как возникает ветер, из чего состоят вода и воздух? На все эти и другие более сложные вопросы старшеклассники находят ответы при изучении физики и химии. Все ученики  6-11 классов получают бесплатно урок физики или химии по выбору (факультативный час).</SubjectInfo>
          <SubjectInfo>Более углубленное изучение этих предметов в нашей школе помогает ребятам лучше
            разобраться в понимании окружающего мира. Мы изучаем физику и химию традиционно,
            то есть по программе, которая, будучи выполненной, позволяет прямо перейти к
            обучению в колледже и в университете.</SubjectInfo>
        </SubjectWrapperContainer>
      </SubjectWrapper>
      <SubjectWrapper>
        <SubjectImage src={russ}/>
        <SubjectWrapperContainer>
          <HeadText>Русский язык и литература</HeadText>
          <SubjectInfo>В нашем Центре русский язык любим и уважаем. Каждый урок русского языка и литературы – это всегда праздник. Наши учителя стараются привить любовь к языку, показать всю красоту и богатство русской культуры.</SubjectInfo>
          <SubjectInfo>Мы пишем диктанты, изложения, сочинения, большое внимание уделяется работе над словом.</SubjectInfo>
          <SubjectInfo>Отрабатывая, закрепляя правила правописания русского языка, дети работают не на отвлеченном материале, а на упражнениях, которые тесным образом связаны с различными аспектами культуры.</SubjectInfo>
        </SubjectWrapperContainer>
      </SubjectWrapper>
    </SubjectsContainer>
  </Container>
}
