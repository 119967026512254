import { Advantages } from "../../components/advantages/ Advantages";
import { Container } from "../../components/Container/Container";
import { Doctors } from "../../components/Doctors/Doctors";
import { Info } from "../../components/Info/Info";
import { News } from "../../components/News/News";
import { Slider } from "../../components/slider/slider";
import { Studies } from "../../components/Studies/Studies";
import {DoctorsPsyh} from "../../components/DoctorsPsyh/DoctorsPsyh";
import {useEffect} from "react";

export const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Slider />
      <Info />
      <Advantages />
      <Studies />
      <Doctors />
      <DoctorsPsyh />
      <News />
    </Container>
  );
};
