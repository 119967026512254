import {Container} from "../Container/Container";
import {OurSchoolSlugWrapper} from "./styled";
import {useLocation} from "react-router-dom";
import {schoolArr} from "../Studies/ourSchoolObj";
import {MainText, SliderDynamicComponent, SliderImg} from "../dynamicPage/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/pagination";
import {HeadText} from "../School/styled";
import {useEffect} from "react";

type SchoolKey = keyof typeof schoolArr;

export const OurSchoolSlug = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const key = location.pathname.split("/")[2] as SchoolKey;

  const ScoolObject = schoolArr[key]
  return <Container>
    <HeadText>{ScoolObject.title}</HeadText>
    <OurSchoolSlugWrapper>
      <SliderDynamicComponent>
        <Swiper
          pagination={{
            clickable: true
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false
          }}>
          {ScoolObject.photos.map((value: any) => {
            return <SwiperSlide>
              <SliderImg src={value} />
            </SwiperSlide>
          })}
        </Swiper>
      </SliderDynamicComponent>
      {ScoolObject.info.map((value: any) => {
        return <MainText>{value}</MainText>
      })}
    </OurSchoolSlugWrapper>
  </Container>
}
