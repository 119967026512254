import axios from "axios";
import { useQuery } from "react-query";

const fetchNew = async (slug: string) => {
  const { data } = await axios.get(
    `https://work.markin.studio/api/v1/get/club/${slug}/object`
  );
  return data.body;
};

export const useStudie = ({slug}: {slug: string}) => {
  const { data, isLoading } = useQuery(["studie", slug], () => fetchNew(slug), {
    onError: (error) => {
      console.log("error", error);
    }
  });

  let resultArray = [];

  if(data?.description){
    // Разделяем строку по тегам <p> и </p>
    let paragraphs = data.description.split(/<\/?p>/).filter(Boolean);

    // Обрабатываем каждый параграф
    resultArray = paragraphs.map((paragraph: any) => {
      // Удаляем символы типа &nbsp; &laquo; &raquo;
      let cleanedString = paragraph.replace(/&[^;\s]+;/g, '');

      // Разделяем по тегам <br /> и убираем пустые строки после trim
      let lines = cleanedString.split(/<br\s*\/?>/).map((s: any) => s.trim()).filter(Boolean);


      return lines;
    });
  }

  return {
    studie: {
      ...data,
      description: resultArray.flat()
    },
    isLoading
  };
};
