import {Container} from "../Container/Container";
import {
  CircleBlock,
  FirstContainer,
  HeadText, MainSmall,
  MainText,
  SecondHeadText,
  SecondorieContainer, SecondorieWrapper,
  TeacherBlock, TeacherBlockBottom,
  TeacherBlockHight, TeacherBlocksHight
} from "./styled";
import secondorieTeach from './../../images/teachers/Secondorie.png'
import {useEffect} from "react";
import {Link} from "react-router-dom";

export const Secondorie = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Container>
    <SecondorieContainer>
      <HeadText>Подготовка к экзаменам по математике в Secondaire</HeadText>
      <FirstContainer>
        <MainText>Уже не первый год в детском центре «Мечта» проходят занятия по подготовке к экзаменам по математике в Secondaire. Работа в группе нацелена на то, чтобы не просто сдать экзамены, но и облегчить обучение математике в местной школе. Ребята изучают материал первого secondaire, восполняя пробелы и систематизируя знания, решают как базовые, так и нестандартные задачи, работают над логическими заданиями, углубляют и расширяют свои математические познания.Продолжается набор в группу на 2024-2025 учебный год.</MainText>
      </FirstContainer>
      <SecondorieWrapper>
      <TeacherBlock>
        <TeacherBlockHight>
          <TeacherBlocksHight>
            <CircleBlock src={secondorieTeach}/>
          </TeacherBlocksHight>
          <TeacherBlocksHight>
            <SecondHeadText>Преподаватель:</SecondHeadText>
            <MainSmall>Ольга Игоревна Худолеева – опытный педагог, знакомый с программами частных школ Монреаля. Многие годы дети, посещающие ее группу, успешно сдают вступительные экзамены в различные школы города.</MainSmall>
          </TeacherBlocksHight>
        </TeacherBlockHight>
        <TeacherBlockBottom>
          <MainSmall>«Программа по математике в местной школе в 1 классе Secondaire призвана углубить понимание материала 6 класса primaire, но в большинстве случаев изучается весьма формально и поверхностно, что не может не огорчать. Уроки по средам в детском центре «Мечта» нацелены на более подробное и вдумчивое изучение курса математики местной школы. В целом, подход русской школы состоит в том, чтобы предоставить цельное изложение, постепенное и плавное усложнение материала, избавить детей от скачков, часто присутствующих в программах местных школ. Наш курс помогает ученикам начать лучше разбираться в математике, увереннее чувствовать себя на уроках».</MainSmall>
        </TeacherBlockBottom>
      </TeacherBlock>
      </SecondorieWrapper>
      <FirstContainer>
        <SecondHeadText>Для кого:</SecondHeadText>
        <MainText>учащиеся 5-6 классов.</MainText>
        <MainText>Именно в это время дети знакомятся с методами математического исследования, овладевают математическим инструментарием и формируют осознанное отношение как к самому понятию «решить задачу», так и к пониманию связи математики с другими областями знаний.</MainText>
      </FirstContainer>
      <FirstContainer>
        <SecondHeadText>Когда:</SecondHeadText>
        <MainText>каждую среду 2 урока по 45 минут:</MainText>
        <MainText>см. в <Link to="/raspisanie">расписании кружков</Link> </MainText>
      </FirstContainer>
      <FirstContainer>
        <SecondHeadText>Как</SecondHeadText>
        <MainText>онлайн (ZOOM)</MainText>
      </FirstContainer>
      <FirstContainer>
        <SecondHeadText>Как построены занятия?</SecondHeadText>
        <MainText>- Рассматриваются отдельные темы, включающие как базовые (опорные) задачи, так и нестандартные задания</MainText>
        <MainText>- Решается очень много логических задач</MainText>
        <MainText>- Обязательно домашнее задание, которое позволяет закрепить полученные знания, получить подробную обратную связь от учителя.</MainText>
        <MainText>- Все рассматриваемые на уроках задания тщательно отобраны искомпонованы, взяты из учебников местных и французских школ, тестовых заданий, предлагавшихся в школах Канады и Франции, изсборников логических задач и задач повышенной сложности, есть задачи из русских учебников, переведённые на французский язык, а также задания, придуманные самим учителем.</MainText>
        <MainText>- В 5 классе очень подробно изучаются все темы, задания из которыхпредлагаются на вступительных экзаменах либо рассматриваются впервом Secondaire.</MainText>
        <MainText>- В 6 классе, в сентябре-октябре, разбираются тесты по математике илогике, а потом, когда экзамены для поступления в Secondaire обычно заканчиваются, изучается материал первого secondaire и решаются логические задачи.</MainText>
      </FirstContainer>
      <FirstContainer>
        <SecondHeadText>Стоимость:</SecondHeadText>
        <MainText>70$/месяц для учеников Детского Центра «Мечта»,</MainText>
        <MainText>80$/месяц для тех, кто не является учеником нашей школы.</MainText>
      </FirstContainer>
      <FirstContainer>
        <SecondHeadText>Для записи:</SecondHeadText>
        <MainText>Телефоны:  +1(514) 795-46-77 и +1(514) 712-50-12</MainText>
      </FirstContainer>
    </SecondorieContainer>
  </Container>
}
