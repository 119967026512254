import axios from "axios";
import { useQuery } from "react-query";

const fetchNew = async (slug: string) => {
  const { data } = await axios.get(
    `https://work.markin.studio/api/v1/get/news/${slug}/object`
  );
  return data.body;
};

export const useNew = ({slug}: {slug: string}) => {
  const { data, isLoading } = useQuery(["news", slug], () => fetchNew(slug), {
    onError: (error) => {
      console.log("error", error);
    }
  });

  let resultArray = [];

  if(data?.description){
    // Разделяем строку по тегам <p> и </p>
    let paragraphs = data.description.split(/<\/?p>/).filter(Boolean);

    // Обрабатываем каждый параграф
    resultArray = paragraphs.map((paragraph: any) => {
      // Удаляем символы типа &nbsp; &laquo; &raquo;
      let cleanedString = paragraph.replace(/&[^;\s]+;/g, '');

      // Разделяем по тегам <br /> и убираем пустые строки после trim
      let lines = cleanedString.split(/<br\s*\/?>/).map((s: any) => s.trim()).filter(Boolean);


      return lines;
    });
  }

  const linkRegex = /^<a.*<\/a>$/;

// Функция для извлечения ссылок из <a> тегов
  const extractLink = (str: any) => {
    const match = str.match(/href="([^"]+)"/);
    return match ? match[1] : null;
  };

// Инициализируем два массива для текстов и ссылок
  const texts: any = [];
  const links: any = [];

// Проходим по массиву и распределяем элементы
  resultArray.flat().forEach((item: any) => {
    if (linkRegex.test(item)) {
      // Если элемент — это <a>, извлекаем ссылку и добавляем в массив ссылок
      const link = extractLink(item);
      if (link) {
        links.push(link);
      }
    } else {
      // Если элемент — это текст, добавляем его в массив текстов
      texts.push(item);
    }
  });

  return {
    news: {
      ...data,
      description: texts,
      video: links
    },
    isLoading,

  };
};
