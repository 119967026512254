import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/pagination";

import { HeadText } from "../School/styled";
import {
  DynamicPageContainer,
  MainText,
  SliderDynamicComponent,
  SliderImg, VideoContainer
} from "./styled";

import { Container } from "../Container/Container";
import {useLocation} from "react-router-dom";
import {useNew} from "./hook/useNew";
import {useEffect} from "react";

export const DynamicPage = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const location = useLocation();
    const {news, isLoading} = useNew({slug: location.pathname.split("/")[2]})


  if(!isLoading && news){
    return (
      <Container>
        <DynamicPageContainer>
          <HeadText>{news.title}</HeadText>
          <MainText>
            {news.description[0]}
          </MainText>
          <SliderDynamicComponent>
            {news.otherImagesNews.length > 0 ? <Swiper
              pagination={{
                clickable: true
              }}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 25000,
                disableOnInteraction: false
              }}>
              {news.otherImagesNews.map((value: any) => {
                return <SwiperSlide>
                  <SliderImg src={value.image} />
                </SwiperSlide>
              })}

            </Swiper> : <SliderImg src={news.image} />}

          </SliderDynamicComponent>
          {news.description.slice(1, news.description.length).map((value: any) => (<MainText>{value}</MainText>))}
          <VideoContainer>
          {news.video && news.video.map((url: any, index: any) => {
            const getYouTubeVideoId = (url2: any) => {
              const match = url2.match(/(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|watch\?.+&v=))([^?&]+)/);
              return match ? match[1] : null;
            };
            const videoId = getYouTubeVideoId(url);

            return <iframe
              width="400"
              height="250"
              src={`https://www.youtube.com/embed/${videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={`YouTube Video ${index + 1}`}
            ></iframe>
          })}
          </VideoContainer>
        </DynamicPageContainer>
      </Container>
    );
  }
  return <div>Loading....</div>
};
