import styled from "styled-components";

export const SubjectsContainer = styled.div`
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
`

export const SubjectWrapper = styled.div`
    display: flex;
    margin-top: 40px;
    @media(max-width: 600px){
        display: block;
    }
`
export const SubjectWrapperContainer = styled.div`
    margin-left: 15px;
`

export const SubjectInfo =styled.p``

export const HeadText = styled.p`
    font-size: 30px;
    margin-top: -7px;
    @media(max-width: 600px){
        margin-top: 10px;
    }
`

export const SubjectImage = styled.img`
    width: 500px;
    height: 300px;
    @media(max-width: 600px){
        max-width: 500px;
        width: 100%;
        height: auto;
    }
`
