import styled from "styled-components";

export const NewsPageWrapper = styled.div``


export const NewsBlock = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 30px;
    @media(max-width: 600px){
        grid-template-columns: 1fr;
        gap: 30px;
    }
`
