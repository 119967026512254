import { InfoBlock } from "../InfoBlock/InfoBlock";
import {
  HeadText,
  StudiesBlock,
  StudiesBlockAround,
  StudiesContainer
} from "./styled";
import {useStudies} from "./useStudies";
import { Link } from "react-router-dom";
import {schoolArr} from "./ourSchoolObj";

export const Studies = () => {
  const {studies} = useStudies()

  return (
    <StudiesContainer>
      <HeadText>Наша школа</HeadText>
      <StudiesBlockAround>
      {Object.entries(schoolArr).map(([key, value]) => {
        return <Link to={`/ourschool/${value.link}`}> <InfoBlock name={value.title} img={value.photo}/></Link>
      })}
      </StudiesBlockAround>
      <HeadText>Студии и кружки</HeadText>
      <StudiesBlock>
        <StudiesBlockAround>
          {studies && studies.map((value: any) => {
            return <Link to={`/class/${value.slug}`}> <InfoBlock name={value.title} img={value.image}/></Link>
          })}
        </StudiesBlockAround>

      </StudiesBlock>
    </StudiesContainer>
  );
};
